import React from "react";

function Correct(props) {
  return (
    <div
      className={`flex  whitespace-nowrap	 text-sm items-center justify-center  px-4 ml-1 mb-2 sm:mb-4 py-4 rounded-3xl h-6   cursor-pointer select-none	${props.selected
          ? "bg-red-500 text-white"
          : "text-red-500 bg-white border-red-500 border-2"
        }`}
      onClick={props.onClick}
    >
      {props.text}
    </div>
  );
}

export default Correct;
