import {
  INSERT_PERFORMANCE_DATA,
  SET_TEST_DATA,
  SET_TEST_SELECTED_GROUP,
  GET_PERFORMANCE_DATA,
  UPDATE_PERFORMANCE_DATA,
  DELETE_PERFORMANCE_DATA,
  INSERT_PERFORMANCE_DATA_DIRECTLY,
  UPDATE_PERFORMANCE_DATA_BY_SKILL_USER_TEST,
  SET_STRUCTURED_DATA,
} from "./actionTypes";

export const setTestData = (data) => {
  return {
    type: SET_TEST_DATA,
    payload: data,
  };
};
export const setTestGroup = (group) => {
  return {
    type: SET_TEST_SELECTED_GROUP,
    payload: group,
  };
};
export const insertPerformanceData = (data) => {
  return {
    type: INSERT_PERFORMANCE_DATA,
    payload: data,
  };
};
export const insertPerformanceDataDirectly = (data) => {
  // After submission of the form, to the redux.
  return {
    type: INSERT_PERFORMANCE_DATA_DIRECTLY,
    payload: data,
  };
};
export const insertPerformanceDataBySkillUserTest = (data) => {
  // After submission of the form, to the redux.
  return {
    type: UPDATE_PERFORMANCE_DATA_BY_SKILL_USER_TEST,
    payload: data,
  };
};
export const updatePerformanceData = (data) => {
  return {
    type: UPDATE_PERFORMANCE_DATA,
    payload: data,
  };
};
export const deletePerformanceData = (data) => {
  return {
    type: DELETE_PERFORMANCE_DATA,
    payload: data,
  };
};
export const getPerformanceData = (data) => {
  return {
    type: GET_PERFORMANCE_DATA,
    payload: data,
  };
};
export const setStructuredData = (data) => {
  return {
    type: SET_STRUCTURED_DATA,
    payload: data,
  };
};
