// import configration from "../config.json";

export const guidGenerator = () => {
  const S4 = () => {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return (
    S4() +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    S4() +
    S4()
  );
};
export const parseBoolean = (boolValue) => {
    return boolValue === 1 ? true : false;
  };

export const isLogin = localStorage.mup_auth_token
  ? localStorage.mup_auth_token
  : false;



export const logout = () => {
  localStorage.isLoggedIn = false;
  localStorage.mup_auth_token = false;
  return true;
};
