import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AiFillDelete } from "react-icons/ai";
import { FaFileCsv, FaRedoAlt, FaUserAlt } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import Grade from "./Grade";
import { insertPerformance } from "../../services/api";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { renderToString } from "react-dom/server";
import jsPDF from "jspdf";
import PdfPreview from "./PdfPreview";
import { CSVLink } from "react-csv";

const ErrorSessionModal = ({ closer, test, performances }) => {
  const theme = useSelector((state) => state.theme);
  const [errorData, setErrorData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    let failedSessions = localStorage.getItem("failedGradings");
    if (failedSessions) {
      failedSessions = JSON.parse(failedSessions);
      let testErrors = failedSessions?.[test?.id];
      setErrorData(testErrors);
    }
    setLoading(false);
  }, []);

  const deleteHandler = (i) => {
    let data = errorData;
    data?.splice(i, 1);
    setErrorData([...data]);
    let failedSessions = localStorage.getItem("failedGradings");
    failedSessions = JSON.parse(failedSessions);
    failedSessions[test?.id] = data;
    if (!data?.length && Object.keys(failedSessions).length == 1) {
      localStorage.removeItem("failedGradings");
      closer();
    } else {
      localStorage.setItem("failedGradings", JSON.stringify(failedSessions));
    }
  };

  const retryHandler = (i) => {
    const cb = (success, data, performance) => {
      if (success) {
        toast.success(t("Restored Successfully"));
        deleteHandler(i);
      } else {
        toast.error(t("Error from api"));
      }
    };
    let data = errorData[i];
    insertPerformance(
      data?.student_id,
      data?.test_id,
      data?.skillSetChildrenSelected?.[0]?.id,
      data?.score_id,
      data?.comment,
      data,
      cb
    );
  };

  const retryAllHandler = () => {
    errorData?.map((each, i) => {
      retryHandler(i);
    });
  };

  const [showPreview, setShowPreview] = useState(false);

  const closePreview = () => {
    setShowPreview(false);
  };
  const openPreview = () => {
    setShowPreview(true);
  };

  const [csvData, setCsvData] = useState([
    [
      "comment",
      "evaluator_id",
      "id",
      "score_id",
      "skill_id",
      "tenant_id",
      "test_id",
      "user_id",
    ],
  ]);

  useEffect(() => {
    if (errorData) {
      let update = csvData;
      errorData?.map((each) => {
        let data = [];
        data.push(each.comment);
        data.push(each.evaluator_id);
        data.push(each.id || null);
        data.push(each.score_id);
        data.push(each.skill_id);
        data.push(each.tenant_id);
        data.push(each.test_id);
        data.push(each.user_id);
        update.push(data);
      });
      setCsvData([...update]);
    }
  }, [errorData]);

  let [AllcsvData, setAllCsvData] = useState([
    [
      "comment",
      "evaluator_id",
      "id",
      "score_id",
      "skill_id",
      "tenant_id",
      "test_id",
      "user_id",
    ],
  ]);

  useEffect(() => {
    if (performances) {
      let update = AllcsvData;
      performances?.map((each) => {
        let data = [];
        data.push(each.comment);
        data.push(each.evaluator_id);
        data.push(each.id || null);
        data.push(each.score_id);
        data.push(each.skill_id);
        data.push(each.tenant_id);
        data.push(each.test_id);
        data.push(each.user_id);
        update.push(data);
      });
      errorData?.map((each) => {
        let data = [];
        data.push(each.comment);
        data.push(each.evaluator_id);
        data.push(each.id || null);
        data.push(each.score_id);
        data.push(each.skill_id);
        data.push(each.tenant_id);
        data.push(each.test_id);
        data.push(each.user_id);
        update.push(data);
      });
      setAllCsvData([...update]);
    }
  }, [performances, errorData]);

  return (
    <>
      {showPreview && <PdfPreview data={errorData} closer={closePreview} />}
      <div>
        <div className={"modal-root-cont "} data-theme={theme}>
          <div className="root-modal-overlay" onClick={closer}></div>

          <div
            className={
              "root-modal-container error_session_root rounded-lg dark:bg-gray-900 bg-gray-50  overflow-auto"
            }
          >
            {/* <button
              className="btn btn-primary error_info_goBack"
              onClick={openPreview}
            >
              {t("Download")}
            </button> */}
            <div className="flex justify-between sticky top-0 z-50 p-1 bg-gray-50 dark:bg-gray-800">
              <div className="flex gap-2">
                <CSVLink data={csvData}>
                  <button className="btn bg-green-700 flex gap-1 align-center">
                    <FaFileCsv /> {t("errors")}
                  </button>
                </CSVLink>
                <CSVLink data={AllcsvData}>
                  <button className="btn bg-green-700 flex gap-1 align-center">
                    <FaFileCsv /> {t("All")}
                  </button>
                </CSVLink>

                <button className="btn btn-primary " onClick={retryAllHandler}>
                  {t("Retry All")}
                </button>
              </div>

              <button className="btn bg-red-700 " onClick={closer}>
                {t(" Go Back")}
              </button>
            </div>

            <ul className="grid grid-cols-1 gap-6 sm:grid-cols-1 p-4 ">
              {errorData?.map((each, i) => (
                <li
                  key={each?.failed_at}
                  className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200 dark:bg-gray-800 dark:text-gray-50"
                >
                  <div className="w-full flex items-center justify-between p-6 space-x-6">
                    <div className="flex-1 truncate">
                      <div className="flex items-center space-x-3">
                        <h3 className="dark:text-white text-gray-900 text-sm font-bold truncate">
                          {each?.test_name}
                        </h3>
                        {/* <span className="flex-shrink-0 inline-block px-2 py-0.5 text-green-800 text-xs font-medium bg-green-100 rounded-full">
                        {each?.skillSetChildrenSelected?.name}
                      </span> */}
                      </div>
                      <p className="mt-1 dark:text-white  text-black-500 font-medium text-sm truncate">
                        {each?.skillSetChildrenSelected?.[0]?.name}
                      </p>
                      <p className="mt-1 dark:text-white text-gray-500 text-sm truncate">
                        {each?.comment}
                      </p>
                      <div className="flex gap-2 mt-3">
                        <FaUserAlt
                          className="  text-gray-500 "
                          aria-hidden="true"
                        />
                        <h3 className="dark:text-white text-gray-900 text-sm font-bold truncate">
                          {each?.student_name}
                        </h3>
                      </div>
                      <p className="mt-1 text-gray-500 dark:text-gray-400 text-sm truncate">
                        {each?.failed_at}
                      </p>
                    </div>

                    <Grade
                      grade={each?.grade || ""}
                      detailView={true}
                      hasMessage={"none"}
                    />
                  </div>
                  <div>
                    <div className="-mt-px flex divide-x divide-gray-200">
                      <div className="w-0 flex-1 flex">
                        <button
                          onClick={() => retryHandler(i)}
                          className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 dark:text-gray-400 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
                        >
                          <FaRedoAlt
                            className="w-5 h-5 text-gray-400 "
                            aria-hidden="true"
                          />
                          <span className="ml-3 ">Retry</span>
                        </button>
                      </div>
                      <div className="-ml-px w-0 bg-red-100 flex-1 flex">
                        <button
                          onClick={() => deleteHandler(i)}
                          className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500"
                        >
                          <MdDelete
                            className="w-5 h-5   text-red-700"
                            aria-hidden="true"
                          />
                          <span className="ml-3 text-red-700 ">Delete</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default ErrorSessionModal;
