
import React, { useCallback, useMemo, useState } from 'react';
import ImageCapture from 'react-image-data-capture';

export function ImageCaptureComponent() {
  const [imgSrc, setImgSrc] = useState(null);
  const [imgFile, setImgFile] = useState(null);
  const onCapture = (imageData) => {
    // read as webP
    setImgSrc(imageData.webP);
    // read as file
    setImgFile(imageData.file);
    // read as blob
    // imageData.blob
  };
  
  // Use useCallback to avoid unexpected behaviour while rerendering
  const onError = useCallback((error) => { console.log(error) }, []);
  
  // Use useMemo to avoid unexpected behaviour while rerendering
  const config = useMemo(() => ({ video: true }), []);
  /*
    { video: true } - Default Camera View
    { video: { facingMode: environment } } - Back Camera
    { video: { facingMode: "user" } } - Front Camera
  */

  // imgFile can be used as a file upload field form submission
  const formData = new FormData();
  formData.append("file", imgFile);
  
  return (
      <div className='flex  flex-column align-center'>
          <div>
          <ImageCapture
        onCapture={onCapture}
        onError={onError}
        width={300}
        userMediaConfig={config}
      />
      </div>
     
      {imgSrc &&
        <div>
          <div>Captured Image:</div>
                  <img src={imgSrc} alt="captured-img" />

        </div>
          }
          <button className="btn btn-primary mt-3 w-100"  disabled={!imgSrc}>Upload Image Review</button>


    </div>
  );
}
