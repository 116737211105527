// import Head from "next/head";
import React, { useEffect } from "react";
// import { useTranslation } from "react-i18next";
import { connect, useSelector } from "react-redux";
import NavBar from "./SubComponents/NavBar";
import TestStartBlock from "./SubComponents/TestStartBlock";
import { data } from "../services/data";
import Spinner from "./Helpers/Spinner";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const TestList = (props) => {
  console.log(`props`, props);
  const { t } = useTranslation()
  const user = useSelector((state) => state.login.user_profile);
  const loggedIn = useSelector((state) => state.login.loggedIn);
  let history = useHistory();
  // console.log('>>>user: ', user);
  // useEffect(() => {
  //   if (!loggedIn) {
  //     history.push("/login");
  //   }
  // }, [loggedIn, history]);
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-martialup-400 dark:bg-gray-900 mt-12">
      <Spinner
        color={"#c4c4c4"}
        size={150}
        spin={
          !props.loggedIn
        }

      />
      <NavBar user={user} />

      <main className="flex flex-col items-center justify-center flex-1  pt-4 sm:px-20 text-center ">
        <div className="lg:text-xl text-center max-w-6xl mt-6 sm:w-full dark:text-indigo-100 text-gray-600 font-semibold">
          {t('ACTIVE GRADINGS')}
        </div>
        <div className="flex flex-wrap items-center justify-around max-w-6xl mt-6 sm:w-full active-grade-cont	">
          {props.active_tests?.map((activeTest, key) => {
            let num = 0;
            const studentLength = activeTest.groups.reduce((acc, curr) => {
              num += parseInt(curr.students.length);
              // eslint-disable-next-line no-lone-blocks
              {
                /* return  acc.students.length + curr.students.length; */
              }
              return num;
            }, 0);

            return (
              <TestStartBlock
                gradingId={activeTest.id}
                groupId={key}
                gradingTitle={activeTest.name}
                groupLength={activeTest.groups.length}
                studentLength={studentLength}
                key={key}
                finalize_grade={activeTest.finalize_grade}
              />
            );
          })}
        </div>
        {/* <div className=" lg:text-xl text-center max-w-6xl mt-6 sm:w-full text-gray-400 font-semibold">
          COMING UP
        </div> */}
        {/* <div className="flex flex-wrap items-center justify-around max-w-6xl mt-6 sm:w-full 	">
          {data.gradings
            .filter((grading) => grading.type === 1)
            .map((gr, key) => {
              let num = 0;
              const studentLength = gr.groups.reduce((acc, curr) => {
                num += parseInt(curr.students.length);
                return num;
              });
              return (
                <TestStartBlock
                  gradingId={key}
                  groupId={key}
                  gradingTitle={gr.title}
                  groupLength={gr.groups.length}
                  studentLength={studentLength}
                  key={key}
                />
              );
            })}
        </div> */}
      </main>

      <footer className="flex items-center justify-center w-full h-24 border-t dark:border-black gap-4">
        <div className="indiviq-version font-thin">
          <span>martialUp v{process.env.REACT_APP_VERSION || "0.1b"}</span>
        </div>
        <a
          className="flex items-center justify-center"
          href="https://indivitech.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://indivi.tech/wp-content/uploads/2020/11/indivi_tech_logo.png"
            alt="IndiviTech Logo"
            className="h-12 ml-2"
          />
        </a>
      </footer>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    active_tests: state.login.active_tests,
    loggedIn: state.login.loggedIn,
    upcoming_tests: state.login.upcoming_tests,
  };
};

export default connect(mapStateToProps, {
  // setLoginSuccess,
  // getPages,
})(TestList);
