import React from 'react'
import './ProgressBar.css'

const ProgressBar = ({ val = 50 ,defaultColor}) => {
    const colorGeneratehandler = () => {
        if (defaultColor) {
            return 'gray'//'rgb(49,46,129)'
        }
        if (val < 1) {
            return 'red'
        }
        if (val < 30) {
            return 'pink'
        }
        else if (val < 60) {
            return 'blue'
        }
         else if (val <= 100) {
            return 'green'
        }
    }
    return (
        <div className='progress-bar-cont is-relative mr-2 dark:bg-gray-800'>
            <div className="progress " style={{"height" : `${val}% `,'backgroundColor':`${colorGeneratehandler(val)}`}}> </div>
        </div>
    )
}

export default ProgressBar
