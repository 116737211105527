import api from "../api";

import { setLoginSuccess } from "./login";

export const details = () => {
  const base_url =
    process.env.NODE_ENV === "_development"
      ? "http://" + localStorage.domain + ".localhost:8003/api/"
      : process.env.REACT_APP_API_URL + "api/";

  console.log("geldi",  base_url);

  return (dispatch) => {
    api
      .getData(base_url + "user_details/") //
      .then((res) => {
        console.log("res", res);
        dispatch(setLoginSuccess(res.data.success));
      })
      .catch((err) => {
        console.error('err', err);
        window.location.hash = `#/login`;

      });
  };
};
