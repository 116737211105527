import Avatar from "react-avatar";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import UserAvatar from "../Test/UserAvatar";
import { progressCirclePercentageHandler } from "../ReUseFunctions";
import Grade from "../Grade";
import ProgressBar from "../../Shared/ProgressBar/ProgressBar";
import SubSkillSetName from "../Test/SubSkillSetName";
// import { guidGenerator } from "../../services/util";

function Row(props) {
  const { student, setStudentInfo, skillSets } = props;
  let { page } = useParams();
  const [structuredData, setStructuredData] = useState()
  // console.log(
  //   "props.selectedSkillSet?.grandchildren: ",
  //   props.selectedSkillSet
  // );
  // console.log("props.keyRow: ", props.keyRow);
  // console.log("window.innerWidth : ", window.innerWidth);
  const { test } = useSelector(store => store.test || {})
  let isTestFinalized = test?.finalize_grade > 0

  let { performances } = useSelector(store => store.test)
console.log(props.students,'students')
  return (
    <tr
      // key={props.keyRow }
      className={`border-b dark:bg-gray-700 dark:border-black  ${props.hasFinished
        ? "border-gray-300 bg-gray-200 hover:bg-gray-300"
        : "border-gray-200 bg-gray-50 hover:bg-gray-100"
        } `}
      >

          <td
                 className={`py-3 px-6 text-left sticky font-bold dark:border-black  dark:bg-gray-700 dark:text-gray-100  left-0 z-10 ${props.hasFinished
                    ? "bg-gray-200 hover:bg-gray-300"
                    : "bg-gray-100 hover:bg-gray-200"
                    } `}
          >
        {props?.skillset?.name}
          </td>
          
   
        
          {props.students?.map((student,i) => {
            
             const studentGrade = props?.performances?.filter(
                (perf) => perf.user_id === student.id && perf.skill_id === props?.skillset?.id
            );
            
            const scoreName = studentGrade && studentGrade[0]
          ? props?.scores?.find((score) => score.id == studentGrade[0]?.score_id)
            ?.name
                : props?.scores[props.scores.length - 1]?.name;
            
             console.log(student,'-',i)
            
            return (
                <td
                className="py-3 px-6 text-left dark:text-gray-100 "
              //  key={props.keyRow + props?.skillset.name}
              >
                <Grade
                  hasFinished={props.hasFinished}
                  grade={scoreName} //check if the skill evaluated
                  id={null}
                  hasMessage={studentGrade && studentGrade[0]?.comment}
                  onClick={() => {
                    if (localStorage.getItem('finalizedTests') !== null) {
                      let finalizedTests = JSON.parse(localStorage.getItem('finalizedTests'));
                      var item = finalizedTests.find(x => x.id == page);
                      if (isTestFinalized) {
                        // alert('This test grades are already finalized')
                        return props.openDetails(student.id, props?.skillset)
                      }
                      else {
                        return props.openDetails(student.id, props?.skillset)
                      }
                    }
                    return props.openDetails(student.id, props?.skillset)
                  }
                  }
                  isOnTestView={true}
                // skillSet={skillSet}
                // student={student}
                />
              </td>
             )
          })}
          
      {/* {props.selectedSkillSet?.grandchildren?.map((skillSet) => {
        const studentGrade = props?.performances?.filter(
          (perf) => perf.user_id === student.id && perf.skill_id === skillSet.id
        );

        const scoreName = studentGrade && studentGrade[0]
          ? props?.scores?.find((score) => score.id == studentGrade[0]?.score_id)
            ?.name
          : props?.scores[props.scores.length - 1]?.name;


        return (
          <td
            className="py-3 px-6 text-left dark:text-gray-100 "
            key={props.keyRow + skillSet.name}
          >
            <Grade
              hasFinished={props.hasFinished}
              grade={scoreName} //check if the skill evaluated
              id={null}
              hasMessage={studentGrade && studentGrade[0]?.comment}
              onClick={() => {
                if (localStorage.getItem('finalizedTests') !== null) {
                  let finalizedTests = JSON.parse(localStorage.getItem('finalizedTests'));
                  var item = finalizedTests.find(x => x.id == page);
                  if (isTestFinalized) {
                    // alert('This test grades are already finalized')
                    return props.openDetails(student.id, skillSet)
                  }
                  else {
                    return props.openDetails(student.id, skillSet)
                  }
                }
                return props.openDetails(student.id, skillSet)
              }
              }
              isOnTestView={true}
            // skillSet={skillSet}
            // student={student}
            />
          </td>
        );
      })} */}

      {/* <td className="py-3 px-6 text-center">
        <div className="flex items-center justify-center ">
          <Grade grade="N/A" hasMessage={false} />
        </div>
      </td>
      <td className="py-3 px-6 text-center">
        <div className="flex items-center justify-center ">
          <Grade grade="A" hasMessage={false} />
        </div>
      </td>
      <td className="py-3 px-6 text-center">
        <div className="flex items-center justify-center ">
          <Grade grade="A" hasMessage={false} />
        </div>
      </td> */}
    </tr>
  );
}
export default Row;
