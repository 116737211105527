import { login } from "../services/api";
import {
  SET_LOGIN_PENDING,
  SET_LOGIN_SUCCESS,
  SET_GRADINGS_FROM_LOCAL,
  SET_SELECTED_SKILL_SET,
  SET_SELECTED_SUB_SKILL_SET, SET_PRAISES,
  //   SET_LIBRARY,
} from "./actionTypes";

export const attemptLogin = () => {
  return (dispatch) => {
    login
      .then(function (data) {
        dispatch(setLoginSuccess());
      })
      .catch(function (err) {
        dispatch(setLoginPending());

        console.info("in the error block");
      });
  };
};

// export const setLibrary = () => {
//   return {
//     type: SET_LIBRARY,
//   };
// };
export const setLoginSuccess = (data) => {
  // console.log('data', data)
  return {
    type: SET_LOGIN_SUCCESS,
    payload: data,
  };
};

export const setQuickResponse = (data) => {
   console.log('data', data)
  return {
    type: SET_PRAISES,
    payload: data,
  };
};


export const setLibraryFromLocal = () => {
  return {
    type: SET_GRADINGS_FROM_LOCAL,
  };
};

export const setLoginPending = () => {
  return {
    type: SET_LOGIN_PENDING,
  };
};

export const setSelectedSkillSet = (data) => {
  return {
    type: SET_SELECTED_SKILL_SET,
    payload: data,
  };
};
export const setSelectedSubSkillSetId = (data) => {
  return {
    type: SET_SELECTED_SUB_SKILL_SET,
    payload: data,
  };
};
