import React, { useEffect, useState } from 'react'

import Spinner from '../../Helpers/Spinner'
import Modal from '../../Shared/Modal/Modal'
import ModalBody from '../../Shared/Modal/ModalBody'
import ModalFooter from '../../Shared/Modal/ModalFooter'
import ModalHeader from '../../Shared/Modal/ModalHeader'

const ConfiramtionModal = ({ show, setShow, confirmationHanlder }) => {
    const [loading,setLoading] = useState(false)

    const closer = () => {
        setShow(false)
    }
    const cb =()=>{
        setLoading(false)
        closer()
    }
 
    return (
        <div>
        <Spinner spin={loading} size={60} />
        <Modal show={show} closeModal={closer} className='delete-modal'>
               <ModalHeader title='finalizing the Test' closeModal={closer}  className="text-primary"/>
                <ModalBody className="">
                    <p className="text-indigo-900 pl-1 dark:text-indigo-100">
                      {`Are you sure To finalize this test, Once finalize it is irreversible process.`}
                    </p>
               </ModalBody>
               <ModalFooter className="">
                    <button className="btn btn-primary" onClick={() => {
                             setLoading(true)
                             confirmationHanlder(cb)
                    }
                    }>
                        Proceed to Finalize
                        
                    </button>
               </ModalFooter>
       </Modal>
        
       </div>
    )
}

export default ConfiramtionModal