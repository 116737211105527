import React from 'react'

const ModalFooter = (props) => {
    return (
        <div className={'custom-modal-footer '+props.className}>
            {props.children}
        </div>
    )
}

export default ModalFooter
