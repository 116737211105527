import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
// import {
//   BrowserRouter as Router,
//   useLocation,
//   useParams,
//   // useRouteMatch,
//   useHistory,
// } from "react-router-dom";
import { setLoginSuccess } from "../actions/login";
import { login } from "../services/api";
// import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Offline } from "react-detect-offline";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Spinner from "./Helpers/Spinner";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

let toastStyle = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const NewLoginPage = (props) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  // const [showModal, setShowModal] = useState(true);
  const [modalText, setModalText] = useState("");

  const schema = yup.object().shape({
    email: yup.string().email("Invalid email").required("Required"),
    password: yup
      .string()
      .required("No password provided.")
      .min(8, "Password is too short - should be 8 chars minimum."),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmit = (data) => {
    console.log(data);
    setIsLoading(true)
    const { email, password } = data;
    login(email.toLowerCase(), password)
      .then(async (response) => {
        console.log("-0-0-0-0response", response);
        setIsLoading(false)
        gotoGradings(response);
        //  window.location.reload();
      })
      .catch((error) => {
        setModalText(t("Please check your login credentials."));
        toast.warn(t("Please check your login credentials."), toastStyle);
        console.log("logged error", error);
        props.setLoginSuccess(false);
        setIsLoading(false)
      })
      .finally(() => {
        //this.props.spinner(false);
        setIsLoading(false)
      });
  };

  // useEffect(() => {
  //   toggleModal();
  // }, [modalText]);
  const gotoGradings = async (response, social = false) => {
    if (social) {

      const status = await localStorage.mup_auth_token;
      console.log("status", status);
      console.log("logged in successfully");
      props.setLoginSuccess(response.success);
      props.history.push("/gradings");
    } else if (response.success.token) {
      localStorage.mup_auth_token = await response.success.token;

      const status = await localStorage.mup_auth_token;
      console.log("status", status);
      console.log("logged in successfully");
      props.setLoginSuccess(response.success);
      console.log('response.success: ', response.success);
      props.history.push("/gradings");
    }
  };

  return (
    <>
      <div className="h-screen w-full flex justify-center items-center bg-gray-50  ">
        <div className="container mx-auto h-screen pt-3 flex flex-col">
          <div className="flex  justify-center items-center ">
            <Spinner
              color={"#c4c4c4"}
              size={150}
              spin={
                isLoading
              }
            />
            <img
              src={process.env.REACT_APP_COMPANY_LOGO_PATH}
              alt="company Logo"
              className="h-12 md:h-24	rounded-md"
            />
            <div className="ml:2 md:ml-4 text-xl md:text-4xl text-gray-800">
              {process.env.REACT_APP_APP_NAME}
            </div>
          </div>
          <div className="flex justify-center px-6  md:my-12 flex-1">
            <div className="w-full xl:w-3/4 lg:w-11/12 flex self-center">
              <div
                className="w-full h-auto bg-gray-200 hidden lg:block  lg:w-1/2 bg-cover rounded-l-lg"
                style={{
                  backgroundImage: `url(
                ${process.env.REACT_APP_UNSPLASH_COLLECTION}
              )`,
                }}
              ></div>

              <div className="w-full lg:w-1/2 bg-white p-5 rounded-lg lg:rounded-l-none">
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="px-8  pb-8  bg-white rounded"
                >
                  <div className=" hidden md:block mb-2 text-gray-700 font-semibold text-center">
                    {t("Welcome Back!")}
                  </div>{" "}
                  <div className="hidden md:block text-sm text-gray-400 italic mb-12 text-center">
                    <p>
                      <Offline>
                        <div className="text-red-300">
                          {t("No internet connection.")}
                        </div>
                      </Offline>
                    </p>
                  </div>
                  <div className="mb-4">
                    <label
                      className="block mb-2 text-sm font-bold text-gray-700"
                      htmlFor="username"
                    >
                      Email
                    </label>
                    <input
                      className={`w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded   ${modalText ? "border-red-500" : ""
                        }
                      shadow appearance-none focus:outline-none focus:shadow-outline`}
                      {...register("email")}
                      id="email"
                      type="text"
                      placeholder="email"
                      disabled={isLoading}
                    />
                    <p className="text-xs italic text-red-500">
                      {errors.email?.message}
                    </p>
                  </div>
                  <div className="mb-4">
                    <label
                      className="block mb-2 text-sm font-bold text-gray-700"
                      htmlFor="password"
                    >
                      Password
                    </label>
                    <input
                      className={`w-full px-3 py-2 mb-3 text-sm leading-tight text-gray-700 border
                   ${modalText ? "border-red-500" : ""}
                   rounded shadow appearance-none focus:outline-none focus:shadow-outline`}
                      {...register("password")}
                      id="password"
                      type="password"
                      placeholder="password"
                      disabled={isLoading}
                    />

                    <p className="text-xs italic text-red-500">
                      {errors.password?.message}
                    </p>
                  </div>
                  <div className="mb-4">
                    <input
                      className="mr-2 leading-tight"
                      type="checkbox"
                      id="checkbox_id"
                      disabled={isLoading}
                    />
                    <label className="text-sm" htmlFor="checkbox_id">
                      Remember Me
                    </label>
                  </div>
                  <div className="xs:mb-6 text-center">
                    <button
                      className="w-full px-4 py-2 font-bold text-white bg-blue-500 rounded-full hover:bg-blue-700 focus:outline-none focus:shadow-outline"
                      type="submit"
                      disabled={isLoading}
                    >
                      Sign In
                    </button>
                  </div>
                  <hr className="mb-2 border-t" />
                  {false && (
                    <div>
                      <div className="text-center">
                        <a
                          className="inline-block text-sm text-blue-500 align-baseline hover:text-blue-800"
                          href="/gradings"
                          disabled={true}
                        >
                          Create an Account!
                        </a>
                      </div>
                      <div className="text-center">
                        <a
                          className="inline-block text-sm text-blue-500 align-baseline hover:text-blue-800"
                          href="./forgot-password.html"
                        >
                          Forgot Password?
                        </a>
                      </div>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
          <footer className="flex items-center justify-center w-full h-24 border-t">
            <div className="indiviq-version font-thin">
              <span>martialUp v{process.env.REACT_APP_VERSION || "0.1b"}</span>
            </div>
            <a
              className="flex items-center justify-center"
              href="https://indivitech.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://indivi.tech/wp-content/uploads/2020/11/indivi_tech_logo.png"
                alt="IndiviTech Logo"
                className="h-12 ml-2"
              />
            </a>
          </footer>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoginPending: state.isLoginPending,
    isLoginSuccess: state.isLoginSuccess,
  };
};

export default connect(mapStateToProps, {
  setLoginSuccess,
  // getPages,
})(NewLoginPage);
