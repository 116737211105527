import React, { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Cog from "../../assets/img/JsxSvgs/Cog";
import useDarkMode from "../../hooks/useDarkMode";
import ChangeLanguage from "../SubComponents/ChangeLanguage";
import "./SettingsDropdown.css";
import { FaFileCsv } from "react-icons/fa";

import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";
const SettingsDropdown = ({ showRotation = false, rotationHandler, user }) => {
  const [show, setShow] = useState(false);
  const performances = useSelector((state) =>state.test.performances)

  const [colorTheme, setTheme] = useDarkMode();
  const themeChanger = () => {
    setTheme(colorTheme);
  };
  const { t } = useTranslation();
  const closeDropdown = () => {
    if (show) setShow(false);
  };

  const themeCheckboxHandler = (e) => {
    if (e.target.checked) {
      setTheme("dark");
    } else {
      setTheme("light");
    }
  };



  let [AllcsvData, setAllCsvData] = useState([
    [
      "comment",
      "evaluator_id",
      "id",
      "score_id",
      "skill_id",
      "tenant_id",
      "test_id",
      "user_id",
    ],
  ]);

  useEffect(() => {
    if (performances) {
      let update = AllcsvData;
      performances?.map((each) => {
        let data = [];
        data.push(each.comment);
        data.push(each.evaluator_id);
        data.push(each.id || null);
        data.push(each.score_id);
        data.push(each.skill_id);
        data.push(each.tenant_id);
        data.push(each.test_id);
        data.push(each.user_id);
        update.push(data);
      });

      setAllCsvData([...update]);
    }
  }, [performances]);
  console.log("AllcsvData: ", AllcsvData);

  return (
    <div className="settings-drop-root  relative">
      <button className="btn btn-primary" onClick={() => setShow(!show)}>
        <div className="svg-icon-default">
          <Cog />
        </div>
      </button>
      {show && (
        <div className="settings-card overflow-visible dark:bg-black bg-gray-200 z-10  drop-card-custom ">
          <div className="card-item-cont bg-gray-100 dark:bg-gray-900 dark:text-indigo-100 mb-1  py-2 px-4 ">
            <p className="card-item">{user?.name + " " + user?.last_name}</p>
          </div>
          {showRotation && (
            <div
              className="card-item-cont bg-gray-100 dark:bg-gray-900 dark:text-indigo-100 mb-1  py-2 px-4 "
              onClick={rotationHandler}
            >
              <p className="card-item">Rotation</p>
            </div>
          )}
          <div
            className="card-item-cont bg-gray-100 dark:bg-gray-900 dark:text-indigo-100 mb-1  py-2 px-4 "
          >
            <CSVLink data={AllcsvData}>
              <button className="btn bg-green-700 flex gap-1 align-center">
                <FaFileCsv /> {t("All")}
              </button>
            </CSVLink>
          </div>

          <div className="card-item-cont py-2 px-4 bg-gray-100 mb-1 dark:bg-gray-900 dark:text-indigo-100">
            <div class="bg-red">
              <input
                type="checkbox"
                class="checkbox"
                checked={colorTheme == "light"}
                onChange={themeCheckboxHandler}
                id="checkbox"
              />
              <div className="flex align-center label-root">
                <label for="checkbox" class="label">
                  <div class="ball"> </div>
                </label>
                <p className="ml-4">Dark </p>
              </div>
            </div>
          </div>
          <div className="card-item-cont py-2 px-4 bg-gray-100 mb-1 dark:bg-gray-900 dark:text-indigo-100">
            <ChangeLanguage />
          </div>
          <p className="card-item mt-4 dark:text-gray-100">
            {user?.domain_name}
          </p>
        </div>
      )}
    </div>
  );
};

export default SettingsDropdown;
