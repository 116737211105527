// import Link from "next/link";
import { HiUserGroup } from "react-icons/hi";
import { useHistory } from "react-router-dom";
import checkSvg from '../../assets/img/svg/checkWhite.svg'

function TestStartBlock(props) {
  let history = useHistory();

  // console.log(`props`, props)
  return (
    <div
      // href={`/exam/${props.bookId}`}
      // key={props.groupId}
      onClick={() => history.push(`/test/${props.gradingId}`)}
    //  className="bg-gray-100 dark:bg-gray-800 p-6  w-full sm:m-6 m-3 text-left border dark:border-black item  lg:w-8/12 xl:w-7/12 rounded-xl hover:bg-white focus:bg-white hover:shadow-lg focus:shadow-lg flex flex-row justify-around shadow  relative"
      className="bg-gray-100 p-6 dark:bg-gray-800 w-full sm:m-6 m-3 text-left border dark:border-black item  lg:w-8/12 xl:w-7/12 rounded-xl hover:bg-white focus:bg-white hover:shadow-lg focus:shadow-lg flex flex-row justify-between shadow  relative"
    >
      {/* {props?.finalize_grade ?
        <div className="finalize-check">
          <img src={checkSvg} />
        </div> : null
      } */}
      <div className="flex flex-wrap  justify-around ">
        <div className="flex w-full justify-between ">
          <h3 className=" px-5 pb-2 text-xl  mt-4 text-left dark:text-indigo-200 ">
            {/* {props.bookName} */}
            {props.gradingTitle}
          </h3>
        </div>
        <div className="flex w-full justify-start">
          <div className="flex text-gray-400">
            <div className="block ml-4 pt-2 md:text-3xl ">
              <HiUserGroup />
            </div>
            <span className="block ml-2 mt-1 md:mt-3">{`${props.groupLength
              } Group${props.groupLength > 1 ? "s" : ""}`}</span>
          </div>
          <div className="flex text-gray-400">
            <div className="block ml-4 pt-2 md:text-3xl">
              <HiUserGroup />
            </div>
            <span className="block ml-2 mt-1 md:mt-3">
              {`${props.studentLength
                } Student${props.studentLength > 1 ? "s" : ""}`}
            </span>
          </div>
        </div>
      </div>
      <div className="self-center">
        <button className={`hover:bg-martialup-900 hover:text-white group flex items-center rounded-lg
        ${props?.finalize_grade ? '  bg-gray-600 ' : ' bg-martialup-800 '} text-white text-sm lg:text-lg font-medium px-6 py-1  self-center shadow-md  "`}>
          {props?.finalize_grade ? "Show" : "Start"}
        </button>
      </div>
    </div>
  );
}
export default TestStartBlock;
