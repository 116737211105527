import React, { useState } from 'react';
import { useEffect } from 'react';
import Avatar from 'react-avatar';
import { useSelector } from 'react-redux';
import ProgressBar from '../../Shared/ProgressBar/ProgressBar';
import { progressCirclePercentageHandler } from '../ReUseFunctions';
import UserAvatar from '../Test/UserAvatar';

const TableHead = ({
    hasFinished=false,
    student,
    skillSets,
    setStudentInfo,

}) => {

    const [structuredData,setStructuredData] = useState()


    let { performances } = useSelector(store => store.test)
    useEffect(() => {
        let structuredData = []
        skillSets?.map(eachSkillset => {
          let SingleData = { skillSet: eachSkillset, studentGrades: [] }
          eachSkillset?.grandchildren?.map((subSkillSet) => {
            const studentGrade = performances?.filter(
              (perf) => perf.user_id === student.id && perf.skill_id === subSkillSet.id
            )
            if (studentGrade?.length) {
              SingleData.studentGrades.push(studentGrade[0])
            }
            else {
              SingleData.studentGrades.push(null)
            }
    
          })
          structuredData.push(SingleData)
        })
        setStructuredData(structuredData)
      }, [skillSets, student, performances])
    return    <th
    className={`py-3 px-6 text-left sticky dark:border-black  dark:bg-gray-800  left-0 z-10 ${hasFinished
      ? "bg-gray-200 hover:bg-gray-300"
      : "bg-gray-100 hover:bg-gray-200"
      } `}

    onClick={() => { setStudentInfo({ show: true, info: student }) }}
  >
    <div
      className={`flex   items-center ${hasFinished ? "space-between" : "justify-center"
        } `}
    >
      <div
        className={`-center items-center align-center flex ${hasFinished ? "flex-row" : "mr-2 flex-col revert-flex-col "
          } `}
      >
        {student.avatar ? (
          <UserAvatar
            hasFinished={hasFinished}
            url={student.avatar}
          />
        ) : (
          <Avatar
            name={student.name + " " + student.lastName}
            round
            maxInitials={2}
            size={
              hasFinished
                ? "30"
                : window.innerWidth < 600
                  ? "60"
                  : "4rem"
            }
          // textSizeRatio={props.hasFinished ? 1.9 : 1.7}
          // textMarginRatio={0.2}
          />
        )}
        <>

          {/* <span
            className={`self-center text-xs  ${props.hasFinished ? "" : ""
              } font-bold text-green-600  `}
          >
            4/5
          </span> */}
        </>
        {/* )} */}
        {/* need to work here */}
        <div className="flex flex-col revert-ml-2 ">
          <div className="flex justify-center mt-1">
            {
              structuredData?.map(each => (
                <ProgressBar
                  defaultColor
                  val={progressCirclePercentageHandler(each)} />
              ))
            }
          </div>
          <span
            className={`self-center text-xs mt-1 dark:text-gray-100   ${hasFinished ? "ml-1 " : ""
              }   `}
          >
            {hasFinished
              ? student.name.substring(0, 3)
              : student.name}
          </span>
        </div>

      </div>
    </div>
  </th>;
};

export default TableHead;
