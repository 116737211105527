// import RadioButton from "./RadioButton";
import React, { useState } from "react";
// import Grade from "./Grade";

import { HiPlusCircle } from "react-icons/hi";
import Praise from "./Praise";
import { insertQuickResponse } from "../../services/api";
import { setQuickResponse } from "../../actions/login";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

let toastStyle = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
};
// import Correct from "./Correct";

function PraiseDetail(props) {
    const [newResponse, setNewResponse] = useState("");
    const [praises, setPraises] = useState(props.praises);
    const storeResponseHandler = (e) => {

        if (newResponse.length > 1) {
            insertQuickResponse(newResponse, newResponse).then((response) => {
                console.log("response.data: ", response.success.data);
                if (response.success.data) {
                    setPraises([...praises, response.success.data]);
                    setNewResponse('');
                    props.setQuickResponse(response.success.data)
                    toast.success('Praise Added Successfully.', toastStyle);
                }
            }).catch((err) => {
                toast.error('Unable to store response.', toastStyle);
                console.log("response.err: ", err);
            });

        } else {
            toast.warn('Minimum character for response is are two.', toastStyle);
        }
    }
    return (
        <div
            className="fixed top-z-index inset-0 overflow-y-auto"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
        >
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div
                    className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                    aria-hidden="true"
                ></div>

                <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true"
                >
                    &#8203;
                </span>

                <div
                    className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div className="sm:items-start">
                            <div className="flex items-center mt-2 ">
                                <div
                                    className="flex  flex-col  bg-gray-50 w-full rounded-lg align-middle p-2 border-b-2 border-gray-200">
                                    <h3
                                        className=" leading-6  text-gray-700 mb-2 font-light"
                                        id="modal-title"
                                    >
                                        New Praise
                                    </h3>
                                    <div className="flex flex-row justify-between ">
                                        <input
                                            name="message"
                                            id="message"
                                            value={newResponse}
                                            placeholder="Good Job!"
                                            className="w-10/12 px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500"
                                            onChange={(e) => {
                                                setNewResponse(e.target.value)
                                            }}
                                            required
                                        />
                                        <span className=" text-5xl text-green-400">
                                            <HiPlusCircle
                                                onClick={storeResponseHandler}
                                            />
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="flex items-center mt-2 ">
                                <div
                                    className="flex items-center bg-gray-50 w-full rounded-lg align-middle p-2 border-b-2 border-gray-200">
                                    <div className="flex flex-col w-full">
                                        <div className="mr-4 flex flex-row justify-between	 w-full		">
                                            <div className="mr-4 flex flex-row justify-between	 w-full		mb-3">
                                                <span className="text-xs text-gray-500">Praises</span>
                                            </div>
                                        </div>
                                        <div className="mr-4 flex overflow-x-scroll  hide-scroll-bar ">
                                            <div className="flex flex-wrap max-h-48">
                                                {praises?.map((praise) => {
                                                    return (
                                                        <Praise
                                                            selected={false}
                                                            text={praise.name}
                                                            /* onClick={() => changeComment(praise.name)}*/
                                                            key={praise.name}
                                                        />
                                                    );
                                                })}
                                                {/*  <Praise selected={false} text="Perfect!" />
                        <Praise
                          selected={false}
                          text="Very nice job Very nice job Very nice job!"
                        />
                        <Praise selected={false} text="Very nice job!" />
                        <Praise selected={false} text="Very nice job!" />
                        <Praise selected={false} text="Very nice job!" />
                        <Praise selected={false} text="Good Job" />
                        <Praise selected={false} text="Good Job" />*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                        <button
                            onClick={props.closePraiseDetails}
                            type="button"
                            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                        >
                            Done
                        </button>
                    </div>
                </div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    );
}

export default connect(null, {
    setQuickResponse,
    // getPages,
})(PraiseDetail);;
