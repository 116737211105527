/* eslint-disable import/no-anonymous-default-export */
import {
  SET_LOGIN_SUCCESS,
  SET_GRADINGS_FROM_LOCAL,
  SET_SELECTED_SKILL_SET,
  SET_SELECTED_SUB_SKILL_SET,
  SET_PRAISES
} from "../actions/actionTypes";

export default (
  state = {
    loggedIn: false,
    library: "",
    domain: "",
    user_profile: "",
    active_tests: [],
    upcoming_tests: [],
    belts: [],
    details: null,
    groups: [],
    quick_responses: [],
    scores: [],
    skills: [],
    selectedSkillSet: null,
    selectedSubSkillSetId: null,

    // url: 1,
  },
  action
) => {
  console.log('state', state)
  // console.log('action.payload', action)
  switch (action.type) {
    case SET_LOGIN_SUCCESS:
      // console.log("action.type", action.type); //TODO remove
      console.log("action.payload", action.payload); //TODO remove
      return {
        ...state,
        loggedIn: !!action?.payload,
        user_profile: action.payload.details,
        active_tests: action.payload.active_tests,
        upcoming_tests: action.payload.upcoming_tests,
        belts: action.payload.belts,
        details: action.payload.details,
        groups: action.payload.groups,
        quick_responses: action.payload.quick_responses,
        scores: action.payload.scores,
        skills: action.payload.skills,
      };

    case SET_GRADINGS_FROM_LOCAL:
      console.log(
        `localStorage.getItem("mup_auth_token")`,
        localStorage.getItem("mup_auth_token")
      );
      if (!localStorage.getItem("mup_auth_token")) {
        window.location.hash = `#/login`;
        return {
          ...state,
        };
      }
      // console.log("localStorage.library", localStorage.library);
      return {
        ...state,
        loggedIn: true,
        // library: JSON.parse(localStorage.library),
        // user_profile: JSON.parse(localStorage.user_profile),
        // url: localStorage.url,
        theme: localStorage.theme || "dark",
      };
    case SET_SELECTED_SKILL_SET:
      return {
        ...state,
        selectedSkillSet: action.payload,
      };
    case SET_PRAISES:
      return {
        ...state,
        quick_responses: state.quick_responses.concat(action.payload),
      };
    case SET_SELECTED_SUB_SKILL_SET:

      return {
        ...state,
        selectedSubSkillSetId: action.payload,
      };

    default:
      return state;
  }
};
