import React, { useEffect, useState } from "react";

import jsPDF from "jspdf";

const PdfPreview = ({ data, closer }) => {
  const print = () => {
    let doc = new jsPDF("p", "pt", "a3");
    doc.setFontSize(0.5);
    doc.html(document.getElementById("print_session"), {
      callback: function (pdf) {
        pdf.output("dataurlnewwindow");
        closer();
      },
    });
  };

  useEffect(() => {
    print();
  }, []);

  console.log(data, "vvvsome data");
  return (
    <div
      id="print_session"
      style={{
        width: "600px",
      }}
    >
      <h3>Failed Sessions</h3>
      <table class="table-auto text-xs">
        <thead>
          <tr class="warning">
            <th className="p-2">Test</th>
            <th className="p-2">Student</th>
            <th className="p-2">Skill </th>
            <th className="p-2">Sub Skill</th>
            <th className="p-2">Grade</th>
            <th className="p-2">Comment</th>
            <th className="p-2">Error at</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((each) => (
            <tr className="p-2">
              <td className="p-2"> {each?.test_name}</td>
              <td className="p-2"> {each?.student_name}</td>
              <td className="p-2">{each?.skillSet?.name} </td>
              <td className="p-2">
                {each?.skillSetChildrenSelected?.[0]?.name}
              </td>
              <td className="p-2">{each?.grade || ""}</td>
              <td className="p-2"> {each?.comment}</td>
              <td className="p-2"> {each?.failed_at}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PdfPreview;
