
    import React from 'react'
import Avatar from 'react-avatar'
import { useSelector } from 'react-redux'
    import Modal from '../Shared/Modal/Modal'
    import ModalBody from '../Shared/Modal/ModalBody'
    import ModalFooter from '../Shared/Modal/ModalFooter'
    import ModalHeader from '../Shared/Modal/ModalHeader'
    import ProgressBar from '../Shared/ProgressBar/ProgressBar'
import ButtonNavigationLeft from './ButtonNavigationLeft'
import ButtonNavigationRight from './ButtonNavigationRight'
    import { ScoreIdtoGrade, SkillIdToPercentageChangehandler } from './ReUseFunctions'
import UserAvatar from './Test/UserAvatar'
    
const UngradedSkillsInfo = (
    {
        show,
        setShow,
        data,
        structuredData,
        setCurrentSkillInfo,
        AllSkillsInfo,
        openDetails,
        hasFinished,
        groupName,
        detail = '',
        setIsSingleInfo = () => { },
        isSingleInfo = false,
        nextStudentHandler,
        prevStudentHandler,
        buttonStatus
    }) => {
        const closer = () => {
            setShow(false)
            setCurrentSkillInfo(AllSkillsInfo)
        }
        const gradeNowHandler = (clickSkillInfo) => {
           // openDetails()
            // console.log(data,'student data')
            // console.log(clickSkillInfo, 'skil data')
            openDetails(data?.id,clickSkillInfo)
    }
    const {finalize_grade} = useSelector(state => state.test.test||{});

        return (
            <div className='full-info-modal'>
                 <Modal show={show} closeModal={closer}>
                        <ModalHeader title={`${data?.name} Ungraded Skills`} closeModal={closer}  className="some class names for custom styles"/>
                    <ModalBody className=" overflow-y-auto  fullinfo-body">
                    <div className="flex items-center w-full mb-2  justify-between bg-gray-50 dark:bg-gray-800 w-full rounded-lg align-middle p-2 border-b-2 border-gray-200 dark:border-gray-700  ">
                    
                    {!isSingleInfo&& <div className="hide-Element">
                    <ButtonNavigationLeft
                        clickHandler={prevStudentHandler}
                        isActive={buttonStatus?.prev}
                      />
                    </div>}
                  

                      <div
                        className={`flex items-center  justify-center    align-middle ${window.innerWidth < 640 ? "" : "p-2"
                          } `}
                      >
                        <div className="mr-4 flex flex-col align-middle justify-center ">
                          {data.avatar ? (
                            <UserAvatar detail={true} url={data.avatar} />
                          ) : (
                            <Avatar
                              className={`${hasFinished ? "w-6" : detail ? "w-28" : "h-full w-32"
                                } rounded-full shadow-md`}
                              name={data.name + " " + data.lastName}
                              round
                              maxInitials={2}
                              size={
                            hasFinished
                                  ? "30"
                                  : window.innerWidth < 640
                                    ? "50"
                                    : "70"
                              }
                              textSizeRatio={hasFinished ? 1.9 : 1.7}
                            />
                          )}
                        </div>
                        <div className="mr-4 flex flex-col 	w-full ">
                          <span className="text-left xs:text-2xl text-martialup-900 dark:text-indigo-300  ">
                            {data.name + " " + data.last_name}
                            {data.age > 0 && <span className="inline ml-2 text-gray-400">
                              {data.age}
                            </span>}
                          </span>
                          <div className=" flex flex-row   w-full mt-2">
                            <span
                              className={`text-left ${window.innerWidth < 640 ? "mr-1" : "mr-10"
                                } text-xs xs:text-base text-gray-500 mb-1  `}
                            >
                              {groupName.length > 12 ? groupName.substring(0, 12) + '..' : groupName}
                          </span>
                          <div
                            style={{
                              backgroundColor: data.rank?.foreground_color === '#ffff00' ? 'gray' : data.rank?.background_color,
                             }}
                            className="text-left  text-xs xs:text-base  rounded-lg  border-gray-50 px-2 pdf-pb-2 pdf-pt-1 font-extrabold  shadow-md">
                              <p
                                 className='pdf-neg-mt-10'
                                style={{
                                  
                                  color: data.rank?.foreground_color,
                                  height: 'fit-content'
                                }}
                              >
                                {data.rank?.name}
                              </p>
                           </div>
                         
                          </div>
                        </div>
                                </div>
                                

                                <div>
                    </div>
                  { !isSingleInfo&& <div className="hide-Element">
                    <ButtonNavigationRight
                        clickHandler={nextStudentHandler}
                        isActive={buttonStatus?.next}
                      />
                    </div>}
                   
                    </div>
                        <div className=" full-report-table-cont dark:text-indigo-300">
                            <div className=" table-flex  ">
                                <p className='head bg-indigo-900 dark:bg-gray-800 text-white pl-3 py-2 ' >Skill Name</p>
                                <p className='head bg-indigo-900 dark:bg-gray-800 text-white pl-3 py-2 ' >Grade</p>
                                <p className=' head bg-indigo-900 dark:bg-gray-800 text-white pl-3 py-2' >Action</p>
                            </div>
                            {structuredData?.map(each => (
                                <>
                                <p className='pl-3 py-2 bg-gray-300  dark:bg-black '>{each?.skillSet?.name}</p>
                                    <div className=" ">
                                        {
                                            each?.skillSet?.grandchildren.map((subSkill,i) => 
                                                <div className='table-flex border-bottom-grey py-2'>
                                                    <p className=' pl-3 py-2 bg-grey' >{subSkill?.name}</p>
                                                    <div className=" flex align-center justify-center ">
                                                        <p className='  grade-z-index mr-2 flex-center' >{ ScoreIdtoGrade(each?.studentGrades[i]?.score_id)  }</p>
                                                        <div className="rotate-90">
                                                        <ProgressBar val={SkillIdToPercentageChangehandler(each?.studentGrades[i]?.score_id)} />
                                                        </div>
                                                    </div>
                                                    <button className="btn-primary btn"
                                                        disabled = {finalize_grade==1}
                                                        onClick={() => gradeNowHandler(subSkill)}>Grade Now</button>
                                                </div>
                                                )
                                        }
                                    </div>
    
                               </>
                            ))}
                            {!structuredData?.length ?
                            <p className='p-2'>No pending Ungraded List</p>:null
                        }
                        </div>
                        </ModalBody>
                        <ModalFooter className="some class names for custom styles">
                          
                        </ModalFooter>
                </Modal>
            </div>
        )
    }             
    
    export default UngradedSkillsInfo
    

