import React from "react";
import {
  HashRouter as Router,
  // BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";
import NewLoginPage from "./components/NewLoginPage.js";
// import MainPage from "./components/MainPage";
// import Library from "./components/Library";

import configureStore from "./store/configureStore";
import { Provider } from "react-redux";
// import { isLogin } from "./services/util";
import ProtectedRoute from "./components/routes/ProtectedRoute";
import TestList from "./components/TestList";
import Test from "./components/SubComponents/Test";

// import Echo from "laravel-echo";
import { ToastContainer, toast } from 'react-toastify';

import { PusherProvider } from "@harelpls/use-pusher";
import useDarkMode from "./hooks/useDarkMode.jsx";
import { Offline, Online } from "react-detect-offline";
import ConnectionLost from "./components/ConnectionLost/ConnectionLost.jsx";


const config = {
  clientKey: "ad1784ea262c063b4343",
  cluster: "mt1",
  broadcaster: "pusher",
  forceTLS: true,
  // authEndpoint: "https://acme.martialup.com/api/broadcasting/auth",
  // auth: {
  //   headers: { Authorization: "Bearer " + localStorage.mup_auth_token,  Accept: 'application/json', },
  // },
};
/* const options = {
  broadcaster: "pusher",
  forceTLS: true,
  key: "ad1784ea262c063b4343",
  cluster: "mt1",
  authEndpoint: "https://acme.martialup.com/api/broadcasting/auth",
  auth: {
    headers: {
      Authorization: "Bearer " + localStorage.mup_auth_token,
      Accept: "application/json",
    },
  },
};

const echo = new Echo(options);

echo.channel("acme-martialup").listen("my-event", (e) => {
  alert("ok");
  console.log(e.order.name);
});
 */
const store = configureStore();


function App() {
  const [colorTheme, setTheme] = useDarkMode()
  return (
    <PusherProvider {...config}>
      <Provider store={store}>
        <ToastContainer />

        <Offline>        <ConnectionLost />
        </Offline>
        <Router>

          <Switch>
            <Route path="/login" component={NewLoginPage} />
            <ProtectedRoute
              exact={true}
              path="/gradings"
              component={TestList}
            />
            <ProtectedRoute path="/" exact={true} component={TestList} />
            <ProtectedRoute
              path="/test/:page"
              // exact={true}
              component={Test}
            />
          </Switch>
        </Router>
      </Provider>
    </PusherProvider>
  );
}

export default App;
