function ButtonNavigationLeft(props) {
  return (
    <button
      onClick={props.isActive ? props.clickHandler : undefined}
      disabled={!props.isActive}
      className={`p-2 ${
        window.innerWidth < 640 ? "" : "mr-2"
      } rounded-full border-2 hover:bg-gray transition duration-300 ${
        props.isActive
          ? props.isDark
            ? "text-gray-200 border-gray-200"
            : "text-gray-600 border-gray-600"
          : props.isDark
          ? "text-gray-600 border-gray-600"
          : "text-gray-100 border-gray-200"
      }`}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M15 19l-7-7 7-7"
        />
      </svg>
    </button>
  );
}
export default ButtonNavigationLeft;
