import React, { useState } from 'react'
import Avatar from 'react-avatar'
import { useSelector } from 'react-redux'
import Modal from '../Shared/Modal/Modal'
import ModalBody from '../Shared/Modal/ModalBody'
import ModalFooter from '../Shared/Modal/ModalFooter'
import ModalHeader from '../Shared/Modal/ModalHeader'
import ProgressBar from '../Shared/ProgressBar/ProgressBar'
import RoundedProgressBar from '../Shared/RoundedProgressBar/RoundedProgressBar'
import UserAvatar from './Test/UserAvatar'
import './StudentInfoModal.css'
import EyeSvg from '../../assets/img/svg/eye_on.svg'
import StudentFullInfo from './StudentFullInfo'
import { progressCirclePercentageHandler, SkillIdToPercentageChangehandler } from './ReUseFunctions'
import { useEffect } from 'react'
import UngradedSkillsInfo from './UngradedSkillsInfo'
import ButtonNavigationLeft from './ButtonNavigationLeft'
import ButtonNavigationRight from './ButtonNavigationRight'

const StudentInfoModal = ({
  show,
  setShow,
  data,
  hasFinished = false,
  detail = false,
  groupName = '',
  skillSets,
  openDetails,
  allStudentsData
}) => {
  const [studentData,setStudentData] = useState({})
  const [showFullInfo, setShowFullInfo] = useState(false)
  const [showUngradedInfo, setUngradedInfo] = useState(false)
  const [AllSkillsInfo,setAllSkillInfo] = useState([])
  const [currentSkillInfo, setCurrentSkillInfo] = useState([])
  const [ungaradedData, setUngradedData] = useState()
  const [isSingleInfo, setIsSingleInfo] = useState(false)
  const [buttonStatus,setButtonStatus] = useState({prev:true,next:true})
  
  useEffect(() => {
    setStudentData(data)
    let currentIndex = allStudentsData?.findIndex(each => each.id == data.id)
    if (currentIndex == 0) {
      setButtonStatus({...buttonStatus,prev:false})
    }
    if (currentIndex == allStudentsData?.length-1) {
      setButtonStatus({...buttonStatus,next:false})
    }
  }, [])
  
  console.log(studentData,'student data')


        const opener = () => {
            setShow(true)
        }
        const closer = () => {
            setShow(false)
    }
    // console.log(data, 'student data')
    // console.log(skillSets, 'skilsets logging')

    let {performances} = useSelector(store=>store.test)
    
    // console.log(performances,'performances')
    // props.selectedSkillSet?.grandchildren?.map((skillSet) => {
    //     const studentGrade = props?.performances?.filter(
    //         (perf) => perf.user_id === student.id && perf.skill_id === skillSet.id
    //     )
    // })
  
  
  const ungradedFilterHandler = (allSkillsinfo) => {
    console.log(allSkillsinfo,'all skills')
    let finalUngradedData =[]
    allSkillsinfo?.map(eachSkillInfo => {
      console.log(eachSkillInfo,'each')
      let currentSkill = {...eachSkillInfo};
      let currentStudentGrades = currentSkill?.studentGrades;
      let currentGrandChildren = currentSkill?.skillSet?.grandchildren;
      let ungradedIndexes = []
      currentStudentGrades?.map((each,i) => {
        if (each && each.score_id != 7) {
          return
        } 
        else {
          ungradedIndexes.push(i);
        }
      })
      console.log(ungradedIndexes,' ungraded indexes checking...')
      if (ungradedIndexes?.length) {
        let filteredStudentGrades = currentStudentGrades?.filter((each,i)=>ungradedIndexes.includes(i))
        let filteredGrandChildren = currentGrandChildren?.filter((each, i) => ungradedIndexes.includes(i))
       // its error code that auto updating state below code without setstate
        // currentSkill.skillSet.grandchildren = filteredGrandChildren;
        // currentSkill.studentGrades = filteredStudentGrades
        // finalUngradedData.push(currentSkill)
       // let update = {...currentSkill}
        let updateSkillSet = { ...currentSkill.skillSet }
        updateSkillSet.grandchildren = filteredGrandChildren
        currentSkill.skillSet = updateSkillSet;
        currentSkill.studentGrades = filteredStudentGrades
        finalUngradedData.push(currentSkill)
      }
    })
    //console.log(finalUngradedData,'final ungarad data')

    if (finalUngradedData?.length) {
      setUngradedData(finalUngradedData)
    }
  }
  //console.log(ungaradedData,'ungraded data')

  useEffect(() => {
    let structuredData = []
    skillSets?.map(eachSkillset => {
        let SingleData = {skillSet : eachSkillset, studentGrades : []}
        eachSkillset?.grandchildren?.map((subSkillSet) => {
            const studentGrade = performances?.filter(
                (perf) => perf.user_id === studentData.id && perf.skill_id === subSkillSet.id
            )
            if (studentGrade?.length) {
                SingleData.studentGrades.push(studentGrade[0])
            }
            else {
                SingleData.studentGrades.push(null)
            }
            
        })
        structuredData.push(SingleData)
    })
    setAllSkillInfo(structuredData)
    setCurrentSkillInfo(structuredData)
    ungradedFilterHandler(structuredData)
  },[skillSets,performances,studentData])

   

  
  
  const ungradedClickHandler = () => {
    setUngradedInfo(true)
  }

  const nextStudentHandler = () => {
    let currentIndex = allStudentsData?.findIndex(each => each.id == studentData.id)
    if (currentIndex < allStudentsData?.length-1) {
      let nextStudentData = allStudentsData[currentIndex + 1]
      if (currentIndex + 1 == allStudentsData?.length - 1) {
        setButtonStatus({...buttonStatus,next:false})
      }
      else {
        setButtonStatus({...buttonStatus,prev:true})
      }
      setStudentData(nextStudentData)
    }
  }
  const prevStudentHandler = () => {
    let currentIndex = allStudentsData?.findIndex(each => each.id == studentData.id)
    if (currentIndex !=0) {
      let prevStudentData = allStudentsData[currentIndex - 1]
      if (currentIndex - 1 == 0) {
        setButtonStatus({...buttonStatus,prev:false})
      }
      else {
        setButtonStatus({...buttonStatus,next:true})
      }
      setStudentData(prevStudentData)
    }

    console.log(currentIndex)
  }
        return (
          <div>
            <StudentFullInfo
              structuredData={currentSkillInfo}
              data={studentData}
              show={showFullInfo}
              setShow={setShowFullInfo}
              setCurrentSkillInfo={setCurrentSkillInfo}
              AllSkillsInfo={AllSkillsInfo}
              openDetails={openDetails}
              hasFinished={hasFinished}
              groupName={groupName}
              detail={detail}
              setIsSingleInfo={setIsSingleInfo}
              isSingleInfo={isSingleInfo}
              nextStudentHandler={nextStudentHandler}
              prevStudentHandler={prevStudentHandler}
              buttonStatus ={buttonStatus}
            
            />
            {showUngradedInfo &&
              <UngradedSkillsInfo
                structuredData={ungaradedData}
                data={studentData} show={showUngradedInfo}
                setShow={setUngradedInfo}
                setCurrentSkillInfo={setCurrentSkillInfo}
                AllSkillsInfo={AllSkillsInfo}
              openDetails={openDetails}
              hasFinished={hasFinished}
              groupName={groupName}
              detail={detail}
              nextStudentHandler={nextStudentHandler}
              prevStudentHandler={prevStudentHandler}
              buttonStatus ={buttonStatus}
              />}
            
            <Modal show={show} closeModal={closer} className="dark:bg-gray-900" >
                    <ModalHeader title={`${studentData?.name} details : `} closeModal={closer}  className="some class names for custom styles"/>
                    <ModalBody >
                    <div className="flex items-center ">
                    <div className="flex items-center w-full   justify-between bg-gray-50 w-full dark:bg-gray-800 rounded-lg align-middle p-2 border-b-2 border-gray-700">
                      <ButtonNavigationLeft
                        clickHandler={prevStudentHandler}
                        isActive={buttonStatus?.prev}
                      />

                      <div
                        className={`flex items-center  justify-center    align-middle ${window.innerWidth < 640 ? "" : "p-2"
                          } `}
                      >
                        <div className="mr-4 flex flex-col align-middle justify-center ">
                          {studentData.avatar ? (
                            <UserAvatar detail={true} url={studentData.avatar} />
                          ) : (
                            <Avatar
                              className={`${hasFinished ? "w-6" : detail ? "w-28" : "h-full w-32"
                                } rounded-full shadow-md`}
                              name={studentData.name + " " + studentData.lastName}
                              round
                              maxInitials={2}
                              size={
                            hasFinished
                                  ? "30"
                                  : window.innerWidth < 640
                                    ? "50"
                                    : "70"
                              }
                              textSizeRatio={hasFinished ? 1.9 : 1.7}
                            />
                          )}
                        </div>
                        <div className="mr-4 flex flex-col 	w-full ">
                          <span className="text-left xs:text-2xl text-martialup-900 dark:text-indigo-300 mb-1">
                            {studentData.name + " " + studentData.last_name}
                            {studentData.age > 0 && <span className="inline ml-2 text-gray-400">
                              {studentData.age}
                            </span>}
                          </span>
                          <div className=" flex flex-row   w-full">
                            <span
                              className={`text-left ${window.innerWidth < 640 ? "mr-1" : "mr-10"
                                } text-xs xs:text-base text-gray-500 mb-1  `}
                            >
                              {groupName.length > 12 ? groupName.substring(0, 12) + '..' : groupName}
                            </span>
                            <span
                              className="text-left  text-xs xs:text-base  rounded-lg  border-gray-50 px-2 font-extrabold  shadow-md"
                              style={{
                                backgroundColor: studentData.rank?.foreground_color === '#ffff00' ? 'gray' : studentData.rank?.background_color,
                                color: studentData.rank?.foreground_color,
                                height: 'fit-content'
                              }}
                            >
                              {studentData.rank?.name}
                            </span>
                          </div>
                        </div>
                                </div>
                                

                                <div>
                     </div>
                      <ButtonNavigationRight
                         clickHandler={nextStudentHandler}
                         isActive={buttonStatus?.next}
                      />
                    </div>
                  </div>



                    <div className="mt-3 mb-4 dark:text-indigo-300">
                        { studentData?.preferred_name&&<div className="student-info-cont">
                            <div className="flex mb-1">
                                <p className='font-bold custom-label'>Preffered Name </p>
                                <p>{studentData?.preferred_name}</p>
                            </div>
                        </div>}
                        <div className="student-info-cont">
                            <div className="flex mb-1">
                                <p className='font-bold custom-label'>Address </p>
                                <p>{studentData?.address||'' +' '+studentData?.city||""}</p>
                            </div>
                        </div>
                        <div className="student-info-cont">
                            <div className="flex mb-1">
                                <p className='font-bold custom-label'>Phone </p>
                                <p>{studentData?.phone}</p>
                            </div>
                        </div>
                  {studentData?.special_needs&&<div className="student-info-cont">
                            <div className="flex mb-1">
                                <p className='font-bold custom-label'>Special Needs </p>
                                <p>{studentData?.special_needs}</p>
                            </div>
                        </div>}
  
                   </div>

                        <div className="flex report-of-all-skills">
                            {
                                currentSkillInfo?.map(each => (
                                  <div className="flex flex-col bg-gray-50 dark:bg-gray-800  report-each-skill  " onClick={() => {
                                    setShowFullInfo(true)
                                    setIsSingleInfo(true)
                                    setCurrentSkillInfo([each])
                                  }}>
                                        <p className='text-center skillName mb-1 dark:text-indigo-300 '>{each?.skillSet?.name}</p>
                                        <div className="rounded-progress-cont">
                                            <RoundedProgressBar each={each}  percentage={progressCirclePercentageHandler(each)} />
                                        </div>
                                        <div className="flex">
                                                    {each?.studentGrades?.map(studentGrade => 
                                                        <ProgressBar defaultColor val={SkillIdToPercentageChangehandler(studentGrade?.score_id)} />
                                                    )}
                                        </div>
                                       <img src={EyeSvg} className='view-svg-in-card' alt="" />
                            </div>
                                ))
                            }
                            
                        </div>

                    </ModalBody>
                    <ModalFooter className="flex">
                        <button className="btn-primary btn mr-3" onClick={() => {
                          setShowFullInfo(true)
                          setCurrentSkillInfo(AllSkillsInfo)
                        } }>Show full report</button>
                        <button className="btn-secondary btn" onClick={ungradedClickHandler }>Show Ungraded List</button>
                    </ModalFooter>
            </Modal>
             
            </div>
        )  
}

export default StudentInfoModal
