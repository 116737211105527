import React from 'react'

const ModalHeader = ({title,closeModal,className}) => {
    return (
        <div className={"modal-header flex justify-between "+className}>
            <p className="modal-title text-indigo-900 dark:text-indigo-300 ">{title}</p>
            <p className="close-btn" onClick={closeModal}></p>
        </div>
    )
}

export default ModalHeader
