function UserAvatar(props) {
  return (
    <img
      alt={"Users Name"}
      className={`${
        props.hasFinished ? "w-6" : props.detail ? "w-28" : "h-full w-32"
      } rounded-full shadow-md`}
      size={props.hasFinished ? "30" : "150"}
      src={props.url}
    />
  );
}
export default UserAvatar;
