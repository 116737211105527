
function SubSkillSetName(props) {
  return (
    <th

      className={`sticky top-0 z-30 invert-sticky-left  capitalize dark:text-gray-100 bg-gray-100 py-3 px-6 h-24  text-center border-gray-300 border-b dark:bg-gray-800 `}
//      className={`sticky top-0 z-30 invert-sticky-left  capitalize bg-gray-100 py-3 px-6 h-24  text-center border-gray-300 border-b dark:bg-gray-800 dark:text-gray-200`}


    >
      {props.skillSetName}
    </th>
  );
}
export default SubSkillSetName;
