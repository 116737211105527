import React from "react";
import { useTranslation } from "react-i18next";
import connectionLost from "./connectionlost.png";
import "./ConnectionLost.css";
const ConnectionLost = () => {
  const { t } = useTranslation();
  return (
    <div className="connectionLost_root">
      <div className="connectionLost_data card p-4">
        <div className="flex gap-3 mobile-flex-column-reverse">
          <div className="connectionLost_left_cont flex flex-column ">
            <h1 className="mb-3 no_connection_head">
              {t("Internet Connection Lost")}
            </h1>
            <p className="mb-4">
              Seems to be Your Internet Connection is not working , Turning on
              Your <b>Wifi</b> or <b>Mobile data</b> to continue Services...
            </p>
            <button
              className="btn-primary btn"
              onClick={() => window.location.reload()}
            >
              {t("RELOAD PAGE")}
            </button>
          </div>
          <div className="connectionLost_right_cont">
            <img src={connectionLost} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConnectionLost;
