import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
// import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import SkillSetName from "./SkillSetName";
import { finalGrade, startTest } from "../../../services/api";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setTestData } from "../../../actions/test";
import ConfiramtionModal from "./ConfirmationModal";
import SettingsDropdown from "../../Shared/SettingsDropdown";

let toastStyle = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
};
const SkillSets = (props) => {
    const [showConfirmation, setShowConfirmation] = useState(false)
    let { page } = useParams();
    console.log(page, 'page')
    let history = useHistory();
    const user = useSelector((state) => state.login.user_profile);

    // setSelectedSkillSet={props.setSelectedSkillSet}
    //       setSelectedSubSkillSetId={props.setSelectedSubSkillSetId}
    //       selectedSkillSet={props.selectedSkillSet}
    //       selectedSubSkillSetId={props.selectedSubSkillSetId}
    const { selectedSkillSet } = props;
    console.log(selectedSkillSet, 'testing')
    // const [selectedSkillSet, setSelectedSkillSet] = useState(
    //   props.skillSets && props.skillSets[0] ? props.skillSets[0].id : null
    // );
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    useEffect(() => {

        if (!selectedSkillSet && props.skillSets && props.skillSets[0]) {
            console.log("props.skillSets[0]: ", props.skillSets[0]);
            props.setSelectedSkillSet(props.skillSets[0]);
        }
        if (selectedSkillSet) {
            console.log(selectedSkillSet, 'selected skillset')
            console.log(props.skillSets, 'props skillset')
            let index = props.skillSets?.findIndex(each => each.id == selectedSkillSet?.id)
            console.log(index)
            if (index == -1) {
                props?.setSelectedSkillSet(props.skillSets[0]);

            }
        }
    }, [props, props.skillSets]);

    let verifyFinalizeTest = (id) => {
        if (localStorage.getItem('finalizedTests') !== null) {
            let finalizedTests = JSON.parse(localStorage.getItem('finalizedTests'));
            let obj = finalizedTests.find(x => x.id == id);
            if (!obj) {
                return false;
            }
            return true;
        }
        return false;

    }
    const { test } = useSelector(store => store.test || {})

    // console.log("-->selectedSkillSet: ", props.test);
    // console.log("!!!-->props.skillSets: ", props.skillSets);
    const dispatch = useDispatch()


    let finalizeApiCall = (cb) => {
        let profile = props.userProfile;
        toast.info('Request for grade finalizing grade is sent.', toastStyle);
        finalGrade(page, profile.email).then((res) => {
            startTest(page)
                .then(async (response) => {
                    // alert('ok')
                    dispatch(setTestData(response))
                    cb()

                })
                .catch((error) => {
                    console.log("logged error", error);
                    cb()
                });

            // if (localStorage.getItem('finalizedTests') !== null) {
            //     let finalizedTests = JSON.parse(localStorage.getItem('finalizedTests'));
            //     var item = finalizedTests.find(x => x.id == res.data.id);
            //     if (!item) {
            //         finalizedTests.push({ id: res.data.id, finalize_grade: res.data.finalize_grade })
            //         localStorage.setItem('finalizedTests', JSON.stringify(finalizedTests));
            //     }
            // } else {
            //     let finalizedTests = [];
            //     finalizedTests.push({ id: res.data.id, finalize_grade: res.data.finalize_grade })
            //     localStorage.setItem('finalizedTests', JSON.stringify(finalizedTests));
            // }
            toast.success('Report sent to your email.', toastStyle);
        }).catch((et) => {
            toast.error('We were unable to process your request this time.', toastStyle);
            cb()
        });
    }

    const finalizeHandler = () => {
        if (test?.finalize_grade == 1) {
            toast.warn('The grades are already finalized for this test.', toastStyle);
        } else {
            setShowConfirmation(true)
        }
    }
    return (
        // <nav className=" bg-martialup-400 w-full flex  justify-between items-center  fixed top-0 z-20 overflow-scroll h-12">
        <nav className=" py-3 scrollbar-hide bg-martialup-400 dark:bg-gray-900" key={props.selectedSkillSet?.id}>
            <div className="mx-auto px-4 ">
                <div className="flex justify-between items-center ">
                    <div className="flex space-x-1">
                        {/* <!-- logo --> */}
                        <p
                            onClick={() => history.push("/")}
                            className="home-logo w-10 h-10 mx-2  text-center md:ml-4 sm:mx-4	text-white bg-indigo-900 font-sans	rounded-full sticky left-0 cursor-pointer select-none	"
                        >
                            <svg
                                className="pl-2 pt-1 w-8 h-8 text-center "
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                                ></path>
                            </svg>
                        </p>
                        {/* <h2 className='mt-2 text-gray-500 test-name'>{props.test ? props.test.name : ''}</h2> */}
                    </div>

                    <div
                        className="hidden scrollbar-hide  sm:flex  flex-grow  items-center  justify-center flex-wrap sm:gap-3 mr-0">
                        {props.skillSets?.map((skillSet) => {
                            return (
                                <SkillSetName
                                    key={skillSet.name}
                                    skillSetName={skillSet.name}
                                    skillId={skillSet.id}
                                    selected={
                                        selectedSkillSet && selectedSkillSet.id === skillSet.id
                                    }
                                    clickHandler={() => props.setSelectedSkillSet(skillSet)}
                                />
                            );
                        })}
                    </div>
                    {/* <!-- mobile button goes here --> */}
                    <div className=" sm:hidden block items-center mobile-menu-button-cont">
                        <button
                            className="mobile-menu-button dark:text-indigo-100"
                            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                        >
                            <svg
                                className="w-6 h-6 dark:text-indigo-100"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M4 6h16M4 12h16M4 18h16"
                                />
                            </svg>
                        </button>
                    </div>
                    <SettingsDropdown user={user} showRotation={true} rotationHandler={props.rotationHandler} />
                    <button
                        onClick={finalizeHandler}
                        style={{
                            color: '#fff',
                            background: test?.finalize_grade == 1 ? 'green' : ''
                        }}
                        className="  inline-flex justify-center rounded-md border border-gray-300  dark:border-black shadow-sm px-4 py-2
              bg-gray-400  font-medium text-gray-700 dark:bg-gray-800
              hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0
              sm:ml-3 sm:w-auto text-sm sm:text-sm"
                    >{test?.finalize_grade > 0 ? 'Finalized' : 'Finalize'}
                    </button>
                </div>
            </div>

            <ConfiramtionModal show={showConfirmation} setShow={setShowConfirmation} confirmationHanlder={finalizeApiCall} />




            {/* <!-- mobile menu --> */}
            {isMobileMenuOpen && (
                <div className="mobile-menu  sm:hidden block ">
                    {props.skillSets?.map((skillSet) => {
                        return (
                            <SkillSetName
                                skillSetName={skillSet.name}
                                skillId={skillSet.id}
                                selected={
                                    selectedSkillSet && selectedSkillSet.id === skillSet.id
                                }
                                clickHandler={() => props.setSelectedSkillSet(skillSet)}
                            />
                        );
                    })}
                </div>
            )}
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </nav>
    );
};
const mapStateToProps = (state) => {
    return {
        // isLoginPending: state.isLoginPending,
        // isLoginSuccess: state.isLoginSuccess,
        test: state.test.test,
        // skills: state.login.skills,
    };
};

export default connect(mapStateToProps, {
    // setLoginSuccess,
    // getPages,
    // setTestData,
})(SkillSets);
