export default {
  translation: {
    "My IndiviBooks": "My IndiviBooks", // My_IndiviBooks
    language: "EN",
    library: "Library",
    Welcome: "Welcome",
    Logout: "Logout",
    Loading: "Loading",
    intro_1:
      "Search Bar: Search images, definitions and other resources! You can also see predefined wordlist here.",
    intro_2:
      "Index: Table of Contents, Index of Activities, Your Bookmarks and Annotations are here!",
    intro_3:
      "Check out new features! Keyboard shortcuts, Capture a part of the page, Themes, Navigation buttons and more!",
    "Interactive Objects": "Interactive Objects", //Interactive_Objects
    "Drawing Objects": "Drawing Objects", // Drawing_Objects
    "Load All Images": "Load All Images", // Load_All_Images
    Drag: "Drag",
    "Drag the page": "Drag the page", //Drag_the_page
    "Zoom out": "Zoom out", //Zoom_out
    "Zoom in": "Zoom in", //Zoom_in
    "Full Screen": "Full Screen", //Full_Screen
    "Draw Toolbar": "Draw Toolbar", //Draw_Toolbar
    "Navigation Tools": "Navigation Tools", //Navigation_Tools
    Capture: "Capture",
    Curtain: "Curtain",
    Settings: "Settings",
    "Vertical Toolbar": "Vertical Toolbar", //Vertical_Toolbar
    "Horizontal Toolbar": "Horizontal Toolbar", //Horizontal_Toolbar
    Pen: "Pen",
    Highlighter: "Highlighter",
    "Clear All": "Clear All", //Clear_All
    Eraser: "Eraser",
    Shapes: "Shapes",
    Line: "Line",
    "Empty Circle": "Empty Circle", //Empty_Circle
    Circle: "Circle",
    "Empty Rectangle": "Empty Rectangle",
    Rectangle: "Rectangle",
    "Line Size": "Line Size",
    "Text and stroke color": "Text and stroke color", //Text_and_stroke_color
    "Fill color": "Fill Color", //Fill_color
    Undo: "Undo",
    Redo: "Redo",
    Language: "Language",
    Dark: "Dark",
    Light: "Light",
    "Dark Blue": "Blue",
    Green: "Green",
    "Go to Previous Page": "Go to Previous Page", //Go_to_Previous_Page
    "Go to Next Page": "Go to Next Page", //Go_to_Next_Page
    "Scroll to top of the page": "Scroll to Top of the page", //Scroll_to_TOP_of_the_page
    "Scroll to end of the page": "Scroll to End of the page", //Scroll_to_END_of_the_page
    Erase: "Erase",
    "Select Drawing Objects": "Select Tool for Drawing Objects",
    Layers: "Layers",
    Theme: "Theme",
    "Change Toolbar Position": "Change Toolbar Position",
    "Toolbar Pin": "Toolbar Pin",
    Text: "Text",
    Search: "Search",
    "Please enter email and password for login.":
      "Please enter email and password for login.",
    "Sign in": "Sign in",
    Login: "Login",
    "If you experience a problem, please contact your domain administrator.":
      "If you experience a problem, please contact your domain administrator.",
    Email: "Email",
    "Email is required": "Email is required",
    "Please enter your email": "Please enter your email",
    "Please enter your password": "Please enter your password",
    "Please enter IndiviBook Domain": "Please enter IndiviBook Domain",
    "IndiviBook domain is required": "IndiviBook domain is required",
    "Password domain is required": "Password domain is required",
    "Please enter a valid email, password and IndiviBook domain.":
      "Please enter a valid email, password and IndiviBook domain.",
    "Please check your login credentials.":
      "Please check your login credentials.",
    "Please fill both the email and password fields.":
      "Please fill both the email and password fields.",
    "IndiviBook Domain": "IndiviBook Domain",
    Page: "Page",
    "My Bookmarks": "My Bookmarks",
    Note: "Note",
    Audio: "Audio",
    Video: "Video",
    Answer: "Answer",
    Activity: "Activity",
    Image: "Image",
    "Open Sidebar": "Open Sidebar",
    "Close Sidebar": "Close Sidebar",
    Library: "Library",
    Back: "Back", ////
    Next: "Next", ////
    Done: "Done", ////
    Skip: "Skip", ////
    TOC: "Table of Contents", ////
    Activities: "Activities", ////
    "My Annotations": "My Annotations", ////
    Bookmark: "Bookmark", ////
    "Stroke Size": "Stroke Size", ////
    Password: "Password", ////
    "Suggested Words": "Suggested Words",
    "Search images from Pixabay": "Search images from Pixabay",
    "Search images from Unsplash": "Search images from Unsplash",
    "Search on Wikizero": "Search on Wikizero",
    "Search on Wikipedia": "Search on Wikipedia",
    "Search on Merriam Dictionary": "Search on Merriam Dictionary",
    "Search on Wiktionary": "Search on Wiktionary",
    "Search on WordWeb": "Search on WordWeb",
    "Search on PBS Learning Media": "Search on PBS Learning Media",
    "Search on Google": "Search on Google",
    "Search on Google Images": "Search on Google Images",

    "Activity Tools": "Activity Tools",
    "Add Note": "Add Note",
    "Add Video": "Add Video",
    "Add Activity": "Add Activity",
    "Add Audio": "Add Audio",
    "Add Image": "Add Image",
    "Question Tools": "Question Tools",
    "Add a Multiple Choice Question": "Add a Multiple Choice Question",
    "Add a Multiple Response Question": "Add a Multiple Response Question",
    "True False": "True False",
    "Drag Drop": "Drag Drop",
    "Fill in the Blank": "Fill in the Blank",
    "Drag": "Drag",
    "Bookmark": "Bookmark",
    "Next Page": "Next Page",
    "Previous Page": "Previous Page",
    "Close Search Bar": "Close Search Bar",
    "Open Search Bar": "Open Search Bar",
    "search": "search",
    "Movable Buttons": "Movable Buttons",

    "Confirm": "Confirm",
    "You moved your own activity. Would you like to save the new position to the server?": "You moved your own activity. Would you like to save the new position to the server?",
    "Yes": "Yes",
    "No": "No",
    "Search images": "Search images",
    "Search Video": "Search Video",
    "Search Vector": "Search Vector",
    "Button Labels": "Button Labels",
    "Please enter your email, password and domain name.": "Please enter your email, password and domain name.",
    "Click a point to add a zoomed page part": "Click a point to add a zoomed page part",
    "Link Name": "Link Name",
    "Advanced Mode": "Advanced Mode",
    "Prev": "Prev",
    "Teacher Tools": "Teacher Tools",
    "Zoom Tools": "Zoom Tools",
    "Add Hideable Answer": "Add Hideable Answer",
    "This object can't be inserted.": "This object can't be inserted.",
    "Submit": "Submit",
    "Cancel": "Cancel",

    "Upload audio": "Upload Audio",
    "Public": "Public",
    "Private": "Private",
    "Top": "Top",
    "Left": "Left",
    "audio": "Audio",
    "Upload image": "Upload Image",
    "image": "Image",
    "Find an image": "Find an image",
    "Style": "Style",
    "Link": "Link",
    "Please enter a valid link like": "Please enter a valid link like",
    "Video Link (Youtube, Vimeo, Facebook, Twitch,SoundCloud, Streamable, Wistia, DailyMotion)": "Video Link (Youtube, Vimeo, Facebook, Twitch,SoundCloud, Streamable, Wistia, DailyMotion)",
    "Video Link": "Video Link",
    "text": "Text",
    "link": "Link",

    "Click a point to add a button": "Click a point to add a button",

    "Select": "Select",
    "Scroll": "Scroll",
    "Click a point to add a zoomed page part": "Click a point to add a zoomed page part",
    "Content is loading": "Content is loading",
    "Delete Activity": "Delete Activity",
    "Button Name": "Button Name",
    "Caps Lock On": "Caps Lock On",
    "Group Selection": "Group Selection",
    "UnGroup Selection": "UnGroup Selection",
    "Duplicate Selection": "Duplicate Selection",
    "Toolbar Size": "Toolbar Size",
    "Brightness Settings": "Brightness Settings",
    "Page Brightness": "Page Brightness",
    "Page Contrast": "Page Contrast",
    "Reset": "Reset",
    "Timer": "Timer",
    "Exit": "Exit",
    "Presentation": "Presentation",
    "Presentation Mode": "Presentation Mode",
    "Full": "Full",
    "Highlight": "Highlight",
    "Cut": "Cut",
    "Confirm": "Confirm",
    "Open/Close": "Open/Close",
    "Add Hide Area": "Add Hide Area",
    "Add Blank Page": "Add Blank Page",
    "Add Presentation Mode": "Add Presentation Mode",











    "ACTIVE GRADINGS": "ACTIVE GRADINGS",


  },
};
