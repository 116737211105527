import React from "react";
import "./DropCard.css";

const DropCard = ({ show, setShow, ...props }) => {
  return (
    <div className="">
      {show && (
        <>
          <div className="back-drop-popup" onClick={() => setShow(false)}></div>
          <div class="dropdown-content root_dropcard overflow-auto  more-drop-card-dist">
            {props?.children}
          </div>
        </>
      )}
    </div>
  );
};

export default DropCard;
