// import Link from "next/link";
import React, { useState, useEffect } from "react";
// import { HiUserGroup } from "react-icons/hi";
import BottomBar from "./BottomBar";
import CorrectDetail from "./CorrectDetail";
import GradeDetail from "./GradeDetail";
// import NavBar from "./NavBar";
import PraiseDetail from "./PraiseDetail";
import Row from "./Row";
import SkillSets from "./Test/SkillSets";
import { connect, useSelector } from "react-redux";
import { insertPerformance, getPerformancesByTest, base_url } from "../../services/api";
// import Pusher from "pusher-js";

import {
    useChannel, useEvent
    // , usePusher
} from "@harelpls/use-pusher";

import {
    useHistory,
    // useLocation,
    useParams,
    // useRouteMatch,
    // useHistory,
} from "react-router-dom";
// import { data } from "../../services/data";
import { startTest } from "../../services/api";
import { guidGenerator } from "../../services/util";
import {
    getPerformanceData,
    insertPerformanceData,
    updatePerformanceData,
    deletePerformanceData,
    setTestData,
    setTestGroup,
    insertPerformanceDataDirectly,
    insertPerformanceDataBySkillUserTest,
} from "../../actions/test";
import {
    setSelectedSkillSet,
    setSelectedSubSkillSetId,
} from "../../actions/login";
import {
    selectSkillSets,
    selectPraiseQuickResponses,
    selectCorrectionQuickResponses,
} from "../../store/configureStore";
import SubSkillSetName from "./Test/SubSkillSetName";
import Spinner from "../Helpers/Spinner";
import StudentInfoModal from "./StudentInfoModal";
import Switch from "../../assets/img/JsxSvgs/Switch";
import TestTable from "./TestTable";
import TestRotatedTable from "./TestRotatedTable.jsx/TestRotatedTable";
import { toast } from "react-toastify";
import ErrorSessionModal from "./ErrorSessionModal";
import axios from "axios";
// import Echo from "laravel-echo";

// import Pusher from "pusher-js";
// import Echo from "laravel-echo";
// import { PusherProvider } from "@harelpls/use-pusher";

// const config = {
//   // required config props
// //   app_id = "1218722"
// // key = "ad1784ea262c063b4343"
// // secret = "4c552da9387200a5566e"
// // cluster = "mt1"
//   // clientKey: "ad1784ea262c063b4343",
//   // cluster: "mt1",
//   broadcaster: "pusher",
//   key: "d4b9af39550bd7832778",
//   cluster: "mt1",
//   forceTLS: true,
//   encrypted: true,

//   // optional if you'd like to trigger events. BYO endpoint.
//   // see "Trigger Server" below for more info
//   triggerEndpoint: "/pusher/trigger",

//   // required for private/presence channels
//   // also sends auth headers to trigger endpoint
//   authEndpoint: "https://acme.martialup.com/api/broadcasting/auth",
//   auth: {
//     headers: { Authorization:  "Bearer " + localStorage.mup_auth_token,
//   },
// },
// };
// console.log('localStorage.mup_auth_token: ', localStorage.mup_auth_token);
// window.Echo = new Echo(config)

const Test = (props) => {
    const [StudentInfo, setStudentInfo] = useState({ show: false, info: null })
    const [showGradeDetail, setshowGradeDetail] = useState(false);
    const [detailStudentId, setshowDetailStudentId] = useState(null);
    const [detailSkill, setshowDetailSkill] = useState(null);
    const [showPraiseDetail, setshowPraiseDetail] = useState(false);
    const [showCorrectDetail, setshowCorrectDetail] = useState(false);
    const [currentStudentIndex, setCurrentStudentIndex] = useState(null);
    const [currentSkillIndex, setCurrentSkillIndex] = useState(null);
    const [currentSubSkillIndex, setCurrentSubSkillIndex] = useState(null);
    const [praises, setPraises] = useState([]);
    const test = useSelector((state) => state.test.test);
    // const id  = useSelector((state) => state.test.test.id);
    // const { id } = useSelector((state) => state?.test?.test);
    // const { test_id_from_redux } = useSelector((state) => state?.test?.test?.id);

    const params = useParams();
    const groupId = params.page;
    console.log('params: ', params);
    const loggedIn = useSelector((state) => state.login.loggedIn);
    let history = useHistory();
    // console.log('>>>user: ', user);
    // useEffect(() => {
    //     if (!loggedIn) {
    //         history.push("/login");
    //     }
    // }, [loggedIn, history]);
    // const options = {
    //   broadcaster: "pusher",
    //   forceTLS: true,
    //   key: "ad1784ea262c063b4343",
    //   cluster: "mt1",
    //   // authEndpoint: "https://acme.martialup.com/api/broadcasting/auth",
    //   // auth: {
    //   //   headers: {
    //   //     Authorization: "Bearer " + localStorage.mup_auth_token,
    //   //     Accept: "application/json",
    //   //   },
    //   // },
    // };

    // const echo = new Echo(options);
    // const channel = echo.channel('acme-martialup');

    // channel.listen(".my-event", (e) => {
    //   alert("ok");
    //   console.log(e);
    // });

    // const [message, setMessages] = useState();
    const channel = useChannel("acme-martialup");
    useEvent(channel, "test-event", ({ message }) => {
        try {
            console.log("message: ", message);
            const { type, result } = message;
            if (result) {
                result.user_id = parseInt(result.user_id);
                result.test_id = parseInt(result.test_id);
                result.skill_id = parseInt(result.skill_id);
                result.score_id = parseInt(result.score_id);
                result.evaluator_id = parseInt(result.evaluator_id);
            }

            if (type === "insert" || type === "update") {
                if (
                    result.evaluator_id === props.user_profile?.id &&
                    result.test_id === props.test.id
                ) {
                    // console.log("insertPerformanceDataBySkillUserTest: ");
                    props.insertPerformanceDataBySkillUserTest(result);
                } else {
                    console.log("insertPerformanceData: ");
                    props.insertPerformanceData(result);
                }
                // }
            } else if (type === "delete") {
                props.deletePerformanceData(result);
            }
        } catch (error) {
            console.log("error", error);
        }

        // console.log("test-event data: ", data);
        // console.log("test-event data: ", data.message);
        // console.log("test-event data: ", data.message.type);
        // alert("ok");

        // setMessages((messages) => [...messages, data]);
    });

    // const { client } = usePusher();
    // console.log(client)
    // console.log(channel)

    // console.log(message);
    // window.Echo.channel('acme-martialup')
    // .listen('my-event', (e) => {
    //     console.log('nihayeeeet',e.order.name);
    // });

    useEffect(() => {
        // console.log('test_id_from_redux: ', id);
        console.log('!!!!!!test: ', test);
        if (test !== undefined && test !== null) {
            if (localStorage.getItem('finalizedTests') !== null) {
                let finalizedTests = JSON.parse(localStorage.getItem('finalizedTests'));
                if (test.finalize_grade > 0) {
                    let _test = finalizedTests.find(x => x.id == test.id);
                    if (!_test) {
                        finalizedTests.push({ id: test.id, finalize_grade: test.finalize_grade })
                        localStorage.setItem('finalizedTests', JSON.stringify(finalizedTests));
                    }
                }
            } else {
                if (test.finalize_grade > 0) {
                    let finalizedTests = [];
                    finalizedTests.push({ id: test.id, finalize_grade: test.finalize_grade })
                    localStorage.setItem('finalizedTests', JSON.stringify(finalizedTests));
                }
            }
        }
        const id = test?.id;
        id && getPerformancesByTest(id).then((response) => {
            console.log("response.data: ", response.success.data);
            props.getPerformanceData(response.success.data);
        });
        // getPerformanceData
    }, [test?.id]);

    useEffect(() => {
        const students = props.selectedGroup?.students;
        const selectedStudentIndex = students?.findIndex(
            (student) => student?.id === detailStudentId
        );
        setCurrentStudentIndex(selectedStudentIndex);
    }, [detailStudentId, props.selectedGroup]);

    useEffect(() => {
        const subSkillSets = props.selectedSkillSet?.grandchildren;

        const currSubSkillIndex = subSkillSets?.findIndex(
            (subSkill) => subSkill?.id === detailSkill?.id
        );
        setCurrentSubSkillIndex(currSubSkillIndex);
    }, [detailSkill, props.selectedSkillSet]);

    useEffect(() => {
        const topSkillSets = props.skillSets;

        const currSkillIndex = topSkillSets?.findIndex(
            (skill) => skill?.id === props.selectedSkillSet?.id
        );
        setCurrentSkillIndex(currSkillIndex);
    }, [props.selectedSkillSet, props.skillSets]);

    useEffect(() => {
        console.log("-----ok", props.skillSets);
        if (!props.test || props?.test?.id != params.page) {
            startTest(params.page)
                .then(async (response) => {
                    console.log("response", response);
                    props.setTestData(response);
                })
                .catch((error) => {
                    console.log("logged error", error);
                });
        }
    }, [groupId, props, props.test, params]);
    useEffect(() => {

        startTest(params.page)
            .then(async (response) => {
                console.log("response", response);
                props.setTestData(response);
            })
            .catch((error) => {
                console.log("logged error", error);
            });

    }, [props?.login?.user_profile?.id]);

    /*  useEffect(() => {
        console.log('props:', props.praises)
        let temp = props.quick_responses.filter((response) => response.type == 0)
        setPraises(temp);
        console.log('Prasises:',praises)
        console.log('state:',props)
      },[props.quick_responses]);*/

    // const [checkedAnswer, setCheckedAnswer] = useState(null);
    // console.log("skillSets: ", props.skillSets);
    // console.log(`data.gradings[groupId]`, data.gradings[groupId]);
    const openDetails = (studentId, skillSet) => {

        console.log(">>>>>>>>skillSet: ", skillSet);
        console.log('>>>>>>>>', studentId)
        setshowGradeDetail(true);
        setshowDetailStudentId(studentId);
        setshowDetailSkill(skillSet);
    };
    const closeDetails = () => {
        setshowGradeDetail(false);
    };

    const backupFailedGradeAction = (failedSession) => {
        let payload = {
            user_id: failedSession?.student_id,
            test_id: failedSession?.test_id,
            evaluator_id: failedSession?.evaluator_id,
            backup: JSON.stringify(failedSession),
        }
        axios.post(`${base_url}performance-backups/`, payload).then(res => {
            console.log(res)
        }).catch(e => {
            console.log(e)
        })
    }


    const cb = (success, data, performance) => {
        console.log(performance, 'performance')
        if (success) {
            console.log(data, 'some data')
        }
        else {
            toast.error("Something went wrong in saving in api");
            let test = props?.test;
            let studentGroup = props?.selectedGroup
            let student = studentGroup?.students?.filter(each => each.id == performance?.user_id)

            console.log(test, '####test')
            //  console.log(studentGroup, '####studentGroup')
            console.log(student, '####student')
            console.log(performance, '####performance')



            const failedSession = {
                skillSet: performance?.SkillSet,
                id: performance?.id || null,
                skill_id: performance?.skill_id || null,
                tenant_id: performance?.tenant_id || null,
                user_id: performance?.user_id || null,
                evaluator_id: performance?.evaluator_id,
                grade: performance?.grade,
                test_name: test?.name,
                test_id: test?.id,
                skillSetChildrenSelected: performance?.skill,
                student_id: performance?.user_id,
                student_name: student?.[0]?.name,
                comment: performance?.comment,
                score_id: performance?.score_id,
                failed_at: new Date()
            }
            backupFailedGradeAction(failedSession)

            let failedSessions = localStorage.getItem('failedGradings');
            if (failedSessions) {
                failedSessions = JSON.parse(failedSessions)
                let currentTestFailedSession = failedSessions?.[test?.id];
                if (Array.isArray(currentTestFailedSession)) {
                    currentTestFailedSession.push(failedSession)
                }
                else {
                    currentTestFailedSession = [failedSession]
                }
                failedSessions[test.id] = currentTestFailedSession
                localStorage.setItem('failedGradings', JSON.stringify(failedSessions))
            }
            else {
                let failedSessions = {
                    [test?.id]: [failedSession]
                }
                localStorage.setItem('failedGradings', JSON.stringify(failedSessions))
            }
        }
    }

    // localStorage.removeItem("failedGradings")

    console.log(props, 'ALL PROPS')
    const submitPerformance = async (student_id, skill_id, grade, comment, closeGradeDetail = false, previousPerformance, isGradeChanged) => {

        if (isGradeChanged) {
            const test_id = props.test.id;
            const gradeObj = props.scores.find((score) => score.name === grade);
            const score_id = gradeObj?.id;
            console.log("score_id: ", score_id);
            console.log('previousPerformance.score_id: ', previousPerformance?.score_id, score_id);
            console.log('previousPerformance.comment: ', previousPerformance?.comment, comment);

            if (previousPerformance?.score_id === score_id
                &&
                (previousPerformance?.comment === comment
                    ||
                    (previousPerformance?.comment === null && comment === ''))) {//skip if its the same val
                return
            }

            const performance = {
                id: guidGenerator(),
                user_id: student_id,
                test_id: props.test.id,
                skill_id,
                score_id,
                comment,
                grade,
                // tenant_id: 3,
                evaluator_id: props.user_profile.id,
                SkillSet: props?.selectedSkillSet,
                skill: props?.selectedSkillSet?.grandchildren?.filter(each => each.id == skill_id)
            };
            await props.insertPerformanceDataDirectly(performance);
            await insertPerformance(student_id, test_id, skill_id, score_id, comment, performance, cb);
            closeGradeDetail && setshowGradeDetail(false);
        }

    };
    const openPraiseDetails = () => {
        setshowPraiseDetail(true);
    };
    const closePraiseDetails = () => {
        setshowPraiseDetail(false);
    };
    const openCorrectDetails = () => {
        setshowCorrectDetail(true);
    };
    const closeCorrectDetails = () => {
        setshowCorrectDetail(false);
    };

    // console.log("props.selectedGroup: ", props.selectedGroup);
    const groupName = props.selectedGroup?.name;
    const selectedStudent = props.selectedGroup?.students?.filter(
        (student) => student.id === detailStudentId
    )[0];

    const selectSkillSet = (prev = false) => {
        const topSkillSets = props.skillSets;
        let selectedSkillId;
        if (prev) {
            selectedSkillId = currentSkillIndex === 0 ? 0 : currentSkillIndex - 1;
        } else {
            selectedSkillId =
                topSkillSets?.length === currentSkillIndex
                    ? currentSkillIndex
                    : currentSkillIndex + 1;
        }
        topSkillSets[selectedSkillId] &&
            props.setSelectedSkillSet(topSkillSets[selectedSkillId]);

        const subSkillSets = topSkillSets[selectedSkillId]?.grandchildren;
        topSkillSets[selectedSkillId] &&
            subSkillSets[0] &&
            setshowDetailSkill(subSkillSets[0]);
    };

    const selectSkillSetById = (id) => {
        const topSkillSets = props.skillSets;
        let filterSkillSet = topSkillSets?.filter(each => each.id == id)
        if (filterSkillSet?.length > 0) {
            props.setSelectedSkillSet(filterSkillSet[0]);
            const subSkillSets = filterSkillSet[0]?.grandchildren;
            setshowDetailSkill(subSkillSets[0]);
        }
    }





    const prevSkill = () => {
        selectSkillSet(true);
    };
    const nextSkill = () => {
        selectSkillSet(false);
    };
    const selectSubSkillSet = (prev = false) => {
        const subSkillSets = props.selectedSkillSet?.grandchildren;
        // console.log('has-currentSubSkillIndex: ', currentSubSkillIndex, subSkillSets?.length);

        let selectedSubSkillId;
        if (prev) {
            selectedSubSkillId =
                currentSubSkillIndex === 0 ? 0 : currentSubSkillIndex - 1;
        } else {
            selectedSubSkillId =
                subSkillSets?.length === currentSubSkillIndex
                    ? currentSubSkillIndex
                    : currentSubSkillIndex + 1;
        }
        console.log("prev: ", prev);
        console.log("detailSkill: ", detailSkill);
        console.log(subSkillSets[selectedSubSkillId]?.id);
        subSkillSets[selectedSubSkillId] &&
            setshowDetailSkill(subSkillSets[selectedSubSkillId]);
    };

    const selectSubSkillSetById = (id) => {
        const subSkillSets = props.selectedSkillSet?.grandchildren;
        let filterSubSkillSet = subSkillSets?.filter(each => each.id == id)
        console.log(filterSubSkillSet, 'some filter subjest ')
        if (filterSubSkillSet?.length > 0) {
            setshowDetailSkill(filterSubSkillSet[0]);
        }
    }



    const prevSubSkill = () => {
        console.log('hasPPPPrev')
        selectSubSkillSet(true);
    };
    const nextSubSkill = () => {
        console.log('hasNNNNNNext')
        selectSubSkillSet(false);
    };

    const selectStudent = (prev = false) => {
        const students = props.selectedGroup?.students;
        let selectedStudentId;
        if (prev) {
            selectedStudentId =
                currentStudentIndex === 0 ? 0 : currentStudentIndex - 1;
        } else {
            selectedStudentId =
                students?.length === currentStudentIndex
                    ? currentStudentIndex
                    : currentStudentIndex + 1;
        }
        // console.log("prev: ", prev);
        // console.log("detailStudentId: ", detailStudentId);
        // console.log(students[selectedStudentId]?.id);
        students[selectedStudentId] &&
            setshowDetailStudentId(students[selectedStudentId]?.id);
    };

    const selectStudentById = (id) => {
        const students = props.selectedGroup?.students;
        let filteredStudent = students?.filter(each => each.id == id)
        if (filteredStudent?.length > 0) {
            setshowDetailStudentId(filteredStudent[0]?.id);
        }

    }
    const prevStudent = () => {
        selectStudent(true);
    };
    const nextStudent = () => {
        selectStudent(false);
    };

    //   console.log('detailStudentId: ', detailStudentId);
    //   console.log('props.selectedGroup?.students: ', props.selectedGroup?.students);
    // console.log("selectedStudent: ", selectedStudent);

    // console.log("~~~~~~~~~~props.selectedSkillSet: ", props.selectedSkillSet);
    // console.log("props.selectedSubSkillSet: ", props.selectedSubSkillSet);

    const hideStudentInfo = () => {
        setStudentInfo({ info: null, show: false })
    }


    const [rotateTable, setRotateTable] = useState(false)

    const transposeTable = () => {
        setRotateTable(state => !state)

    }






    let isTestHaveFailedSession = localStorage.getItem('failedGradings')
    if (isTestHaveFailedSession) {
        isTestHaveFailedSession = JSON.parse(isTestHaveFailedSession)
        isTestHaveFailedSession = isTestHaveFailedSession?.[props?.test?.id]
    }

    const [showListOfErrorSessions, setShowListOfErrors] = useState(false)
    const showErrorList = () => {
        setShowListOfErrors(true)
    }
    const closeErrorList = () => {
        setShowListOfErrors(false)
    }




    return (
        <>
            {showListOfErrorSessions && <ErrorSessionModal
                closer={closeErrorList}
                test={props?.test}
                performances={props.performances}
            />}

            <div className=" bg-gray-50 dark:bg-gray-900 min-h-screen ">
                {isTestHaveFailedSession ? <button
                    onClick={showErrorList}
                    className="p-2 bg-red-700 text-white failed_session_btn">
                    Errors List
                </button> : null}
                <Spinner
                    color={"#c4c4c4"}
                    size={150}
                    spin={
                        props?.groups?.length === 0 || props?.performancesLoading || !props.test || props?.test?.id != params?.page
                    }
                />
                {/* <NavBar/> */}
                {StudentInfo?.show
                    &&
                    <StudentInfoModal
                        skillSets={props.skillSets}
                        show={StudentInfo?.show}
                        setShow={hideStudentInfo}
                        openDetails={openDetails}
                        data={StudentInfo?.info}
                        allStudentsData={props.selectedGroup?.students}
                    />}

                {showGradeDetail && (
                    <GradeDetail
                        student={selectedStudent}
                        studentsLength={props.selectedGroup?.students?.length}
                        groupName={props.selectedGroup?.name}
                        scores={props.scores}
                        performances={props.performances}
                        skillSet={props.selectedSkillSet}
                        skillSetsLength={props.skillSets?.length}
                        // subSkillSets={props.selectedSkillSet}
                        subSkillSetsLength={props.selectedSkillSet?.grandchildren?.length}
                        openDetails={openDetails}
                        detailSkill={detailSkill}
                        closeDetails={closeDetails}
                        openPraiseDetails={openPraiseDetails}
                        closePraiseDetails={closePraiseDetails}
                        openCorrectDetails={openCorrectDetails}
                        closeCorrectDetails={closeCorrectDetails}
                        submitPerformance={submitPerformance}
                        praises={props.praises}
                        corrects={props.corrects}
                        nextStudent={nextStudent}
                        prevStudent={prevStudent}
                        prevSubSkill={prevSubSkill}
                        nextSubSkill={nextSubSkill}
                        prevSkill={prevSkill}
                        nextSkill={nextSkill}
                        selectSubSkillSet={selectSubSkillSet}
                        key={selectedStudent.id}
                        currentStudentIndex={currentStudentIndex}
                        currentSkillIndex={currentSkillIndex}
                        currentSubSkillIndex={currentSubSkillIndex}
                    />
                )}
                {showPraiseDetail && (
                    <PraiseDetail
                        praises={props.praises}
                        openPraiseDetails={openPraiseDetails}
                        closePraiseDetails={closePraiseDetails}
                    />
                )}
                {showCorrectDetail && (
                    <CorrectDetail
                        corrects={props.corrects}
                        openCorrectDetails={openCorrectDetails}
                        closeCorrectDetails={closeCorrectDetails}
                    />
                )}
                <SkillSets
                    skillSets={props.skillSets}
                    setSelectedSkillSet={props.setSelectedSkillSet}
                    setSelectedSubSkillSetId={props.setSelectedSubSkillSetId}
                    selectedSkillSet={props.selectedSkillSet}
                    selectedSubSkillSetId={props.selectedSubSkillSetId}
                    userProfile={props.user_profile}
                    rotationHandler={transposeTable}
                />



                {
                    !rotateTable ?
                        <TestTable
                            test={props.test}
                            params={params}
                            selectedSkillSet={props.selectedSkillSet}
                            selectedGroup={props.selectedGroup}
                            closeCorrectDetails={closeCorrectDetails}
                            closeDetails={closeDetails}
                            closePraiseDetails={closePraiseDetails}
                            openCorrectDetails={openCorrectDetails}
                            openDetails={openDetails}
                            openPraiseDetails={openPraiseDetails}
                            performances={props.performances}
                            scores={props.scores}
                            setStudentInfo={setStudentInfo}
                            skillSets={props.skillSets}
                            hasFinished={false}

                        /> :
                        <TestRotatedTable
                            test={props.test}
                            params={params}
                            selectedSkillSet={props.selectedSkillSet}
                            selectedGroup={props.selectedGroup}
                            closeCorrectDetails={closeCorrectDetails}
                            closeDetails={closeDetails}
                            closePraiseDetails={closePraiseDetails}
                            openCorrectDetails={openCorrectDetails}
                            openDetails={openDetails}
                            openPraiseDetails={openPraiseDetails}
                            performances={props.performances}
                            scores={props.scores}
                            setStudentInfo={setStudentInfo}
                            skillSets={props.skillSets}
                            hasFinished={false}
                        />
                }

                <BottomBar
                    groups={props.groups}
                    selectedGroup={props.selectedGroup}
                    setTestGroup={props.setTestGroup}
                />
            </div>
        </>

    );
};
const mapStateToProps = (state) => {
    return {
        isLoginPending: state.isLoginPending,
        isLoginSuccess: state.isLoginSuccess,
        test: state.test.test,
        user_profile: state.login.user_profile,
        groups: state.test.groups,
        selectedGroup: state.test.selectedGroup,
        skillSets: selectSkillSets(state),
        selectedSkillSet: state.login.selectedSkillSet,
        scores: state.login.scores,
        praises: selectPraiseQuickResponses(state),
        corrects: selectCorrectionQuickResponses(state),
        quick_responses: state.login.quick_responses,
        performances: state.test.performances,
        performancesLoading: state.test.performancesLoading,
        // selectedSubSkillSetId: state.login.selectedSubSkillSetId,
        // selectedSkillSet: selectSelectedSkill(state),
        // selectedSubSkillSet: selectSelectedSubSkills(state),
    };
};

export default connect(mapStateToProps, {
    setTestData,
    setTestGroup,
    setSelectedSkillSet,
    setSelectedSubSkillSetId,
    getPerformanceData,
    insertPerformanceData,
    updatePerformanceData,
    deletePerformanceData,
    insertPerformanceDataDirectly,
    insertPerformanceDataBySkillUserTest,
})(Test);
