import React from 'react'

const Cog = () => {
    return (
        <svg width="268" height="280" viewBox="0 0 268 280" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M264.162 173.581L240.2 159.744C242.619 146.694 242.619 133.306 240.2 120.256L264.162 106.419C266.919 104.844 268.156 101.581 267.256 98.5437C261.012 78.5187 250.381 60.4062 236.488 45.3312C234.35 43.0249 230.862 42.4624 228.162 44.0374L204.2 57.8749C194.131 49.2124 182.544 42.5187 170 38.1312V10.5124C170 7.36244 167.806 4.60619 164.712 3.93119C144.069 -0.681309 122.919 -0.456309 103.287 3.93119C100.194 4.60619 98 7.36244 98 10.5124V38.1874C85.5125 42.6312 73.925 49.3249 63.8 57.9312L39.8937 44.0937C37.1375 42.5187 33.7062 43.0249 31.5687 45.3874C17.675 60.4062 7.04375 78.5187 0.8 98.5999C-0.15625 101.637 1.1375 104.9 3.89375 106.475L27.8563 120.312C25.4375 133.362 25.4375 146.75 27.8563 159.8L3.89375 173.637C1.1375 175.212 -0.1 178.475 0.8 181.512C7.04375 201.537 17.675 219.65 31.5687 234.725C33.7062 237.031 37.1937 237.594 39.8937 236.019L63.8563 222.181C73.925 230.844 85.5125 237.537 98.0563 241.925V269.6C98.0563 272.75 100.25 275.506 103.344 276.181C123.988 280.794 145.138 280.569 164.769 276.181C167.863 275.506 170.056 272.75 170.056 269.6V241.925C182.544 237.481 194.131 230.787 204.256 222.181L228.219 236.019C230.975 237.594 234.406 237.087 236.544 234.725C250.437 219.706 261.069 201.594 267.312 181.512C268.156 178.419 266.919 175.156 264.162 173.581V173.581ZM134 185C109.194 185 89 164.806 89 140C89 115.194 109.194 94.9999 134 94.9999C158.806 94.9999 179 115.194 179 140C179 164.806 158.806 185 134 185Z" fill="white"/>
</svg>

    )
}

export default Cog
