export const     SkillIdToPercentageChangehandler = (score_id) => {
   // console.log(score_id,'grade Id checking')
    if (score_id == 2) {
        return 100
    }
    else if (score_id == 3) {
        return 75
    }
    else if (score_id == 4) {
        return 50
    }
    else if (score_id == 5) {
        return 35
    }
    else if (score_id == 6) {
        return 20
    }
    else if (score_id == 7) {
        return 0
    }
    else return 0
}

export const ScoreIdtoGrade = (score_id) => {
    if (score_id == 2) {
        return "A"
    }
    else if (score_id == 3) {
        return "B"
    }
    else if (score_id == 4) {
        return "C"
    }
    else if (score_id == 5) {
        return "D"
    }
    else if (score_id == 6) {
        return "E"
    }
    else if (score_id == 7) {
        return "N/G"
    }
    else return "N/G"
}
export     const progressCirclePercentageHandler = (each) => {
    let nonFilled = each?.studentGrades?.filter(studentGrade => (studentGrade == null||studentGrade?.score_id == 7)) 
    nonFilled = nonFilled?.length
    let totalLength = each?.studentGrades?.length;
    let percentage = ((totalLength - nonFilled) / totalLength) * 100
    percentage = Math.round(percentage||0)
    return percentage||0;
}
