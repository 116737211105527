import React from "react";
import { useHistory } from "react-router-dom";
import { ImExit } from "react-icons/im";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setLoginSuccess } from "../../actions/login";

function NavBar(props) {
  let history = useHistory();

  const { t } = useTranslation()
  const dispatch = useDispatch();
  const logout = () => {
    localStorage.removeItem("mup_auth_token");
    localStorage.removeItem("isLoggedIn");
    dispatch(setLoginSuccess(false))
    history.push("/login");
  }
  return (
    <nav className=" bg-martialup-900 dark:bg-black w-full flex  justify-between items-center py-1 fixed top-0 z-20">
      <div className=" pl-4  overflow-hidden 	text-white font-sans md:tracking-wide font-semibold">
        <div className=" flex  cursor-pointer select-none ">
          <p className="">
            <img
              onClick={() => history.push("/")}
              src={process.env.REACT_APP_COMPANY_LOGO_PATH}
              alt="company Logo"
              className="h-12 "
            />
          </p>

          <div className="ml-.05 tex-sm md:text-2xl self-center text-white font-sans lg:tracking-wide font-semibold pl-4 items-center  ">
            {process.env.APP_NAME}
          </div>
        </div>
      </div>

      <div className="  overflow-hidden text-center items-start md:text-2xl	text-white font-sans tracking-wide font-semibold		">
        {t("Gradings")} - {props && props.user ? props?.user?.name + ' ' + props?.user?.last_name : ''}
      </div>

      <div className=" text-right pr-2">
        {/* <button
          type="button"
          className="inline-flex items-center relative px-2  rounded-full hover:shadow-lg"
          onClick={() => history.push("/login")}
        > */}
        <span className=" text-5xl text-red-400" title="Logout">
          <ImExit
            onClick={logout}
          />
        </span>
        {/* </button> */}
      </div>
    </nav>
  );
}
export default NavBar;
