import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import english from "./langs/en";
import turkish from "./langs/tr";
import spanish from './langs/sp'
// import config from './config.json'

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: english,
  tr: turkish,
  sp: spanish,

};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources,
    // lng: "en",
    detection: {
      order: ["localStorage", "cookie"],
      lookupCookie: "i18next",
      lookupLocalStorage: "lang",
      caches: ["localStorage", "cookie"],
      cookieMinutes: 10,
      cookieDomain: "indivibook",
    },
    fallbackLng: process.env.REACT_APP_DEFAULT_LANGUAGE,
    supportedLngs: ["tr", "en", "sp"],
    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
