import React from "react";

function Grade(props) {
  const gradeColorClass = () => {
    switch (props.grade) {
      case "A":
        return "bg-green-300";

      case "B":
        return "bg-blue-300";

      case "C":
        return "bg-yellow-300";


      case "D":
        return "bg-pink-300";


      case "E":
        return "bg-red-300";


      default:
        return "bg-gray-300";

    }
  };
  // console.log(
  //   "props.selectedGrade , props.grade: ",
  //   props.selectedGrade,
  //   props.grade
  // );
  const bgColor = !props.selectedGrade
    ? gradeColorClass()
    : props.selectedGrade === props.grade
      ? gradeColorClass()
      : "bg-gray-200";
  return (
    <div
      className="flex items-center justify-center"
      key={'---'}
    >
      <div
        onClick={props.onClick}
        className={` cursor-pointer	 relative flex self-center ${props.hasFinished
          ? "w-5 h-5 xs:w-6  xs:h-6"
          : props.isOnTestView
            ? "w-16 h-16 xs:w-18  xs:h-18 xs:mx:4 mx-8"
            : "w-10 h-10 xs:w-16  xs:h-16"
          }  rounded-full shadow-md text-center 	 ${bgColor} ${props.selected ? " border-4 border-gray-900" : ""
          }`}
      // onClick={props.clickHandler}
      >
        <span
          className={`m-auto font-semibold dark:text-black pdf-neg-mt ${props.hasFinished ? "text-sm" : " sm:text-3xl text-sm"
            } select-none`}
        >
          {props.grade}
        </span>
        {props.hasMessage !== "none" && (
          <div
            className={` absolute -bottom-2 ${props.hasFinished ? "left-4" : "left-10"
              }  md:text-3xl `}
          >
            {/* <RiMessage2Fill /> */}
            <svg
              width={props.hasFinished ? "12" : "24"}
              height={props.hasFinished ? "12" : "24"}
              viewBox="0 0 16 16"
              fill={!props.hasMessage ? "none" : "#333555"}
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.83337 12V11.5H5.33337H2.66671C2.44569 11.5 2.23373 11.4122 2.07745 11.2559C1.92117 11.0997 1.83337 10.8877 1.83337 10.6667V2.66668C1.83337 2.44566 1.92117 2.2337 2.07745 2.07742C2.23373 1.92114 2.44569 1.83334 2.66671 1.83334H13.3334C13.5544 1.83334 13.7663 1.92114 13.9226 2.07742C14.0789 2.2337 14.1667 2.44566 14.1667 2.66668V10.6667C14.1667 10.8877 14.0789 11.0997 13.9226 11.2559C13.7663 11.4122 13.5544 11.5 13.3334 11.5H9.26671H9.05921L8.91268 11.6469L6.45201 14.1143C6.40775 14.1547 6.36416 14.1667 6.33337 14.1667H6.00004C5.95584 14.1667 5.91345 14.1491 5.88219 14.1179C5.85093 14.0866 5.83337 14.0442 5.83337 14V12Z"
                fill={!props.hasMessage ? "#F3F4FA" : "#333555"}
                stroke="#C4C4C4"
              />
            </svg>
          </div>
        )}
      </div>
    </div>
  );
}

export default Grade;
