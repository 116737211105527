/* eslint-disable import/no-anonymous-default-export */
import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { createSelector } from "reselect";
// import { persistStore, persistReducer } from "redux-persist"; //TODO Add Persist

import thunk from "redux-thunk";
import themeReducer from "../reducers/themeReducer";
import loginReducer from "../reducers/loginReducer";
import testReducer from "../reducers/testReducer";
import { isMobile } from "react-device-detect";

export default () => {
  // const userAgent = navigator.userAgent.toLowerCase();

  const store = createStore(
    combineReducers({
      theme: themeReducer,
      login: loginReducer,
      test: testReducer,
    }),

    compose(
      applyMiddleware(thunk),
      process.env.NODE_ENV === "development" &&
        window.navigator.userAgent.includes("Chrome") &&
        !isMobile
        ? window.__REDUX_DEVTOOLS_EXTENSION__ &&
        window.__REDUX_DEVTOOLS_EXTENSION__({ trace: true, traceLimit: 25 })
        : compose
      // ,window.__REDUX_DEVTOOLS_EXTENSION__ &&
      // window.__REDUX_DEVTOOLS_EXTENSION__({ trace: true, traceLimit: 25 })
    )
  );

  return store;
};
/* Reselect Selector Starts */
export const selectTopSkillSets = (state) => state.login.skills;
export const selectTestLevel = (state) => state.test.test?.level;
export const selectTestCycle = (state) => state.test.test?.cycle;
// export const selectTestType = (state) => state.login.user_profile.test_type;
export const selectTestType = (state) => state.test.test?.test_type;


export const selectSkillSets = createSelector(
  selectTopSkillSets,
  selectTestLevel,
  selectTestCycle,
  selectTestType,
  (skillSets, testLevel, testCycle, testType) => {
    console.log(' skillSets: ', skillSets);
    console.log('testType: ', testType);
    console.log('testLevel: ', testLevel);

    return testType == 0
      ? skillSets.length > 0
        ? skillSets.filter((skillSets) => skillSets.name === testLevel)[0]
          ?.grandchildren.filter((skillSet) => skillSet.name === testCycle)[0]
          ?.grandchildren
        : []
      : testType == 1
        ? skillSets.length > 0 ? skillSets[0]?.grandchildren : []
        : skillSets.length > 0 ? skillSets.filter((skillSets) => skillSets.name === testLevel)[0]?.grandchildren : [];
  }
);

// console.log(' skillSets: ', skillSets);
//     console.log('testType: ', testType);
//     console.log('testLevel: ', testLevel);
//     return testType == 0
//     ? skillSets.length > 0
//       ? skillSets.filter((skillSets) => skillSets.name === testLevel)[0]
//         ?.grandchildren.filter((skillSet) => skillSet.name === testCycle)[0]
//         ?.grandchildren
//       : testType == 1
//         ? skillSets.length > 0 ? skillSets[0]?.grandchildren : []
//         : skillSets.length > 0 ? skillSets
//           .filter((skillSets) => skillSets.name === testLevel) : [];
/* Reselect Selector Ends */

/* Reselect Selector Starts */
export const selectQuickResponses = (state) => state.login.quick_responses;


export const selectPraiseQuickResponses = createSelector(
  selectQuickResponses,
  (quickResponses) => {
    return quickResponses?.filter((response) => response.type == 0);
  }
);
export const selectCorrectionQuickResponses = createSelector(
  selectQuickResponses,
  (quickResponses) => {
    return quickResponses?.filter((response) => response.type == 1);
  }
);
/* Reselect Selector Ends */

/* Reselect Selector Starts */
// export const selectTestGroups = (state) => state.test.groups;
// export const selectTestSelectedGroup = (state) => state.test.selectedGroup;

// export const selectSelectedGroup = createSelector(
//   selectTestGroups,
//   selectTestSelectedGroup,
//   (testGroups, testSelectedGroup) => {
//     return (
//       testGroups &&
//       testGroups.filter((group) => group.id === testSelectedGroup)
//     );
//   }
// );
/* Reselect Selector Ends */
/* Reselect Selector Starts */

/* Reselect Selector Ends */

/* SELECTORS WITHOUT RESELECT LIBRARY
    export const selectSkillSets = (state) => {
      return state.login.skills.length > 0 && state.test.test
      ? state.login.skills
      .filter((topSkillSet) => topSkillSet.name === state.test.test.level)[0]
        .grandchildren.filter(
          (skillSet) => skillSet.name === state.test.test.cycle
        )[0].grandchildren
    : [];
};

export const selectSelectedGroup = (state) => {
  return (
    state.test.groups &&
    state.test.groups.filter((group) => group.id === state.test.selectedGroup)
  );
}; */
