


import React from 'react'
import Avatar from 'react-avatar'
import { useSelector } from 'react-redux'
    import Modal from '../Shared/Modal/Modal'
    import ModalBody from '../Shared/Modal/ModalBody'
    import ModalFooter from '../Shared/Modal/ModalFooter'
    import ModalHeader from '../Shared/Modal/ModalHeader'
    import ProgressBar from '../Shared/ProgressBar/ProgressBar'
import ButtonNavigationLeft from './ButtonNavigationLeft'
import ButtonNavigationRight from './ButtonNavigationRight'
import { ImageCaptureComponent } from './ImageCaptureComponent'
    import { ScoreIdtoGrade, SkillIdToPercentageChangehandler } from './ReUseFunctions'
import UserAvatar from './Test/UserAvatar'
import VideoRecorderComponent from './VideoRecorderComponent'
import VoiceRecorderComponent from './VoiceRecorderComponent'
    
const GradeDetailMediaUploadModal = (
    {
        show,
        closer,
        mediaTypeHandler,
        type
        
    }) => {
      
    let title = 'Video Review'
    if (type == 'audio') {
        title = 'Audio Review'
    }
    else if (type == 'camera') {
        title = 'Image Review'
    }

        return (
            <div className='full-info-modal top-z-index'>
                 <Modal show={show} closeModal={closer}>
                        <ModalHeader title={title} closeModal={closer}  className=""/>
                        <ModalBody className=" overflow-y-auto  fullinfo-body">
                        {type == 'video' ? <VideoRecorderComponent /> : null}
                        {type == 'camera' ? <ImageCaptureComponent /> : null}
                        {type == 'audio' ? <VoiceRecorderComponent /> : null}
                        </ModalBody>
                        <ModalFooter className="some class names for custom styles">
                          
                        </ModalFooter>
                </Modal>
            </div>
        )
    }             
    
    export default GradeDetailMediaUploadModal
    


