import React from 'react';
import { guidGenerator } from '../../../services/util';
import Row from './Row';
import SubSkillSetName from '../Test/SubSkillSetName';
import TableHead from './TableHead';

const TestRotatedTable = (
    {
        test,
        params,
        selectedSkillSet,
        selectedGroup,
        scores,
        performances,
        hasFinished = false,
        openDetails,
        closeDetails,
        openPraiseDetails,
        closePraiseDetails,
        openCorrectDetails,
        closeCorrectDetails,
        setStudentInfo,
        skillSets
    }

    
) => {
  
    return <div>
           {test && test?.id == params?.page && <div className="overflow-auto scrollbar-hide">
                <div className="flex flex-col   ">
                    <div className="w-full ">
                        <div className="test-table-skill relative pb-11 ">
                            {/* <div className="switch-rows " onClick={() => transposeTable()}>
                                <Switch />
                            </div> */}
                            <table className=" table-auto relative w-full  content-container   ">
                                <thead className="dark:bg-gray-800">
                                    <tr className="bg-purple-100  text-martialup-900 uppercase text-sm leading-normal  top-0 z-30 dark:text-gray-100  dark:bg-gray-800">
                                        <th className="sticky  shadow-md top-0 w-24   min-w-24 left-0 z-40 capitalize     bg-gray-200 dark:text-gray-100  border-gray-300 py-3 px-6 dark:bg-gray-800 dark:border-black ">
                                            {/* {groupName}
                                            <div className="text-gray-400 text-xs capitalize">
                                                {props.selectedSkillSet?.name}
                                            </div> */}
                                        {test?.name || ''}

                                    </th>
                                    
                                    {
                                        selectedGroup?.students?.map(student => {
                                            const keyRow =
                                            student?.id && selectedSkillSet?.id
                                                ? student.name + student.last_name + selectedSkillSet.id
                                                    : guidGenerator();
                                                   
                                            return (
                                                <TableHead
                                                    hasFinished={false}
                                                    setStudentInfo={setStudentInfo}
                                                    skillSets={skillSets}
                                                    student={student}
                                                
                                                />  
                                              )
                                                })

                                    }
                                    </tr>
                                </thead>
                                <tbody
                                    className="text-gray-600 text-sm font-light "
                                // key="jlkjlkjlkjlkj"
                                >
                                    {selectedSkillSet?.grandchildren?.map((skillset) => {
                                      
                                        return (
                                            <Row
                                                skillset={skillset}
                                                scores={scores}
                                                performances={performances}
                                                selectedSkillSet={selectedSkillSet}
                                                hasFinished={false}
                                                openDetails={openDetails}
                                                closeDetails={closeDetails}
                                                openPraiseDetails={openPraiseDetails}
                                                closePraiseDetails={closePraiseDetails}
                                                openCorrectDetails={openCorrectDetails}
                                                closeCorrectDetails={closeCorrectDetails}
                                                students ={selectedGroup?.students}
                                                setStudentInfo={setStudentInfo}
                                                skillSets={skillSets}
                                                selectedGroup ={selectedGroup}
                                            />
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>}
  </div>;
};

export default TestRotatedTable;
