function SkillSetName(props) {
  console.log(props, 'props')
  return (
    <div
      className={` flex sm:mr-2 md:mr-5  whitespace-nowrap	capitalize  text-sm items-center justify-center py-6 px-4    sm:py-2 rounded-3xl h-6  	text-martialup-900 dark:text-gray-200 font-sans	border-b-4 	${props.selected ? "border-martialup-800" : ""
        }	cursor-pointer select-none`}
      key={props.skillId}
      onClick={props.clickHandler}
    >
      {props.skillSetName}
    </div>
  );
}
export default SkillSetName;
