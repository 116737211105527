import axios from "axios";
// import { insertActivity } from "../actions/addActivity";
// import { INSERT_ACTIVITY } from "../actions/actionTypes";
export const base_url =
  process.env.NODE_ENV === "_development"
    ? "http://" + localStorage.domain + ".localhost:8003/api/"
    : process.env.REACT_APP_API_URL + "api/";

export const login = (email, password) => {
  return new Promise((resolve, reject) => {
    let params = { email: email, password: password };

    axios
      .post(base_url + "login", params)
      .then((res) => {
        const data = res.data;
        console.log("data", data);
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const startTest = (testId) => {
  console.log("startTest:");

  return new Promise((resolve, reject) => {
    axios
      .get(base_url + "start-test/" + testId)
      .then((res) => {
        const data = res.data;
        console.log("start test data", data);
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const socialLogin = (accessToken) => {
  return new Promise((resolve, reject) => {
    let params = { accessToken };

    axios
      .post(base_url + "social-login", params)
      .then((res) => {
        const data = res.data;
        console.log("data", data);
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const insertPerformance = (
  student_id,
  test_id,
  skill_id,
  score_id,
  comment,
  performance,
  cb = () => { }
) => {
  console.log('some')
  // console.log('startTest:');
  return new Promise((resolve, reject) => {
    var formData = new FormData();
    formData.append("user_id", student_id);
    formData.append("test_id", test_id);
    formData.append("skill_id", skill_id);
    formData.append("score_id", score_id);
    formData.append("comment", comment);
    axios
      .post(base_url + "performances/", formData)
      .then((res) => {
        cb(true, res?.data, performance)
        const data = res.data;
        console.log("~~~~inserted performance", data);
        resolve(data);
      })
      .catch((err) => {
        cb(false, err?.response, performance)

        reject(err);
      });
  });
};
export const updatePerformance = (
  id,
  student_id,
  test_id,
  skill_id,
  score_id,
  comment
) => {
  // console.log('startTest:');
  return new Promise((resolve, reject) => {
    var formData = new FormData();
    formData.append("user_id", student_id);
    formData.append("test_id", test_id);
    formData.append("skill_id", skill_id);
    formData.append("score_id", score_id);
    formData.append("comment", comment);
    axios
      .post(base_url + "performances/update/" + id, formData)
      .then((res) => {
        const data = res.data;
        console.log("~~~~inserted performance", data);
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const getPerformances = () => {
  // console.log('startTest:');
  return new Promise((resolve, reject) => {
    axios
      .get(base_url + "performances/")
      .then((res) => {
        const data = res.data;
        console.log("~~~~all performances", data);
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const getPerformancesByTest = (testId) => {
  // console.log('testId:', testId);
  return new Promise((resolve, reject) => {
    axios
      .post(base_url + "getperformances/" + testId)
      .then((res) => {
        const data = res.data;
        // console.log("~~~~all performances", data);
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const getQuickResponses = () => {
  // console.log('startTest:');
  return new Promise((resolve, reject) => {
    axios
      .get(base_url + "quick-responses/")
      .then((res) => {
        const data = res.data;
        console.log("~~~~all quick-responses", data);
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const insertQuickResponse = (name, description, _public = 0, type = 0) => {
  return new Promise((resolve, reject) => {
    var formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);
    formData.append("public", _public); //TODO Add a field so user could be able to make it public
    formData.append("type", type); //0 Praise 1 Correction
    axios
      .post(base_url + "quick-responses/", formData)
      .then((res) => {
        const data = res.data;
        console.log("~~~~inserted quick response", data);
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const updateQuickResponse = (id, name, description) => {
  return new Promise((resolve, reject) => {
    var formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);
    formData.append("public", 0); //TODO Add a field so user could be able to make it public
    formData.append("type", 0); //0 Praise 1 Correction
    axios
      .post(base_url + "quick-responses/update/" + id, formData)
      .then((res) => {
        const data = res.data;
        console.log("~~~~inserted quick response", data);
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};


export const finalGrade = (id, email) => {
  // console.log('startTest:');
  return new Promise((resolve, reject) => {
    axios
      .get(base_url + 'report/pdf?id=' + id + '&email=' + email)
      .then((res) => {
        const data = res.data;
        console.log("~~~~all performances", data);
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const verifyFinalGrade = (id) => {
  // console.log('startTest:');
  return new Promise((resolve, reject) => {
    axios
      .get(base_url + 'verify-test?id=' + id)
      .then((res) => {
        const data = res.data;
        console.log("~~~~ verify test", data);
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

