import React from "react";
// import { useHistory } from "react-router-dom";
import { BiGroup } from "react-icons/bi";
// import { useSelector } from 'react-redux'


function BottomBar(props) {
  const colors = [
    "text-yellow-200",
    "text-pink-300",
    "text-blue-300",
    "text-green-300",
    "text-purple-300",
    "text-white",
    "text-yellow-400",
    "text-pink-400",
    "text-gray-200",
    "text-blue-400",
    "text-green-400",
    "text-purple-400",
  ];
  // let history = useHistory();
  // const selectedGroup = useSelector((state) => state.test.selectedGroup)

  // const [selectedGroup, setSelectedGroup] = useState(
  //   props.groups[0] ? props.groups[0].id : null
  // );
  // useEffect(() => {
  //   if (!selectedGroup && props.groups[0]) {
  //     setSelectedGroup(props.groups[0].id);
  //   }
  // }, [props.groups]);
  // console.log(`props.groups`, props.groups);
  // console.log("selectedGroup: ", selectedGroup);
  return (
    <div className="flex flex-row bottom-0 z-40 fixed w-full bg-gray-700 dark:bg-gray-900 ">
      <div className="whitespace-nowrap ml-4  text-center  md:text-l  my-3	text-gray-400 font-sans	cursor-pointer select-none ">
        Groups :
      </div>
      <div className=" bg-gray-700 flex dark:bg-gray-900   hide-scroll-bar  sticky   overflow-auto bottom-0 z-20 w-11/12">
        <div className="flex flex-1 max-h-12 my-2 justify-around">
          {props.groups.map((group, key) => {
            return (
              <div
                className={` flex whitespace-nowrap ml-4  text-center items-start md:text-l ${props.selectedGroup && props.selectedGroup.id === group.id
                  ? "border-martialup-800 border-b-2"
                  : ""
                  }  my-1	text-white font-sans	cursor-pointer select-none	`}
                key={group.id}
                onClick={() => props.setTestGroup(group)}
              >
                <span className={`text-2xl ${colors[key]}`}>
                  <BiGroup />
                </span>
                <span className={`ml-2 ${colors[key]}`}>{group.name}</span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
export default BottomBar;
