import React from "react";

function Praise(props) {
  return (
    <div
      value={props.text}
      className={`flex  whitespace-nowrap	 text-sm items-center justify-center  px-4 ml-1 mb-2 sm:mb-4 py-4 rounded-3xl h-6  cursor-pointer select-none	${props.selected
          ? "bg-green-500 text-white"
          : "text-green-500 bg-white border-green-500 border-2"
        }`}
      onClick={props.onClick}

    >
      {props.text}
    </div>
  );
}

export default Praise;
