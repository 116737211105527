// import RadioButton from "./RadioButton";
import React, { useState, useEffect } from "react";
import Grade from "./Grade";
import Avatar from "react-avatar";
import { useDebounce, useDebouncedCallback } from "use-debounce";

import { HiPlusCircle } from "react-icons/hi";
import Praise from "./Praise";
import Correct from "./Correct";
import UserAvatar from "./Test/UserAvatar";
import ButtonNavigationLeft from "./ButtonNavigationLeft";
import ButtonNavigationRight from "./ButtonNavigationRight";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import closeSvg from "../../assets/img/svg/close.svg"
import { useSelector } from "react-redux";
import Audio from "../../assets/img/JsxSvgs/Audio";
import Camera from "../../assets/img/JsxSvgs/Camera";
import Video from "../../assets/img/JsxSvgs/Video";
import GradeDetailMediaUploadModal from "./GradeDetailMediaUploadModal";
import Select from 'react-select'
import DropCard from "../Shared/DropCard/DropCard";

let toastStyle = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};
function GradeDetail(props) {
  let { page } = useParams();
  const { student, currentSkillIndex,
    currentSubSkillIndex } = props;
  console.log('------props: ', props);

  // const [canUpdate, setCanUpdate] = useState(false);
  const [initialStudentPerformance, setInitialStudentPerformance] = useState(false);
  const [selectedGrade, setSelectedGrade] = useState(null);
  const [comment, setComment] = useState("");
  const [hasNextSkill, setHasNextSkill] = useState(false);
  // console.log('hasNextSkill: ', hasNextSkill);
  const [hasPrevSkill, setHasPrevSkill] = useState(false);
  // console.log('hasPrevSkill: ', hasPrevSkill);
  const [hasNextSubSkill, setHasNextSubSkill] = useState(false);
  // console.log('hasNextSubSkill: ', hasNextSubSkill);
  const [hasPrevSubSkill, setHasPrevSubSkill] = useState(false);
  const [hasNextStudent, setHasNextStudent] = useState(false);
  // console.log('hasNextStudent: ', hasNextStudent);
  const [hasPrevStudent, setHasPrevStudent] = useState(false);

  const [showMediaModal, setShowMediaModal] = useState({
    type: 'video',
    show: false
  })

  const mediaTypeHandler = (type) => {
    setShowMediaModal({ ...showMediaModal, type })
  }
  const mediaModalCloser = () => {
    setShowMediaModal({ type: 'video', show: false })
  }
  const mediaModalOpen = (type) => {
    setShowMediaModal({ type, show: true })
  }
  // const [isTestFinalized, setIsTestFinalized] = useState(false);
  // console.log('hasPrevStudent: ', hasPrevStudent);

  // studentGrade[0] &&
  //   console.log("________comment studentGrade: ", studentGrade[0].comment);
  const [reviewType, setReviewType] = useState('praise');
  const { test } = useSelector(store => store.test || {})
  let isTestFinalized = test?.finalize_grade > 0


  useEffect(() => {
    console.log('page no:', page)
    // if (localStorage.getItem('finalizedTests') !== null) {
    //   let finalizedTests = JSON.parse(localStorage.getItem('finalizedTests'));
    //   var finalizedTest = finalizedTests.find(x => x.id == page);
    //   if (finalizedTest) {
    //     setIsTestFinalized(true)
    //   }
    // }
    const studentGrade = props.performances.filter(
      (perf) =>
        perf.user_id === student.id && perf.skill_id === props.detailSkill.id
    );
    studentGrade && setInitialStudentPerformance(studentGrade[0])

    const scoreName = studentGrade[0]
      ? props.scores.find((score) => score.id === studentGrade[0]?.score_id)
        ?.name
      : props.scores[props.scores.length - 1]?.name;

    console.log(scoreName, 'testingse 1');
    console.log(studentGrade[0]?.comment, 'testingse 2')
    setSelectedGrade(scoreName);
    setComment(studentGrade[0]?.comment ? studentGrade[0].comment : "");
    // setTimeout(() => {
    //   setCanUpdate(true);
    // }, 500);
    console.log("±±±±±±±±±±±±±±±±±±±±±±±±±±±±±±±±±±±±±±±±±±±±±± useffect ");
  }, [currentSkillIndex,
    currentSubSkillIndex, student.id]);


  const [isChangedGrade, setIsChangedGrade] = useState(false)

  const changeGrade = (grade) => {
    console.log('selectedGrade: ', grade, selectedGrade);
    if (grade !== selectedGrade) {
      if (!isChangedGrade) {
        setIsChangedGrade(true)
      }
      setSelectedGrade(grade);
      console.log('!!!!!!!!!!!!!! updated grade', grade, selectedGrade)
      /* props.submitPerformanceToTop(
         student.id,
         props.detailSkill.id,
         selectedGrade,
         comment
       );*/
    }
  };
  const changeComment = (_comment) => {
    // console.log('!!!!!!!!!!!!!! updated comment')
    // console.log('!!!!!!!!!!!!!! updated comment', _comment)
    let updateComment = comment.length > 0 ? comment + ', ' + _comment : _comment;
    if (!isChangedGrade) {
      setIsChangedGrade(true)
    }
    setComment(updateComment)

  };
  const setTextAreaComment = (value) => {
    if (!isChangedGrade) {
      setIsChangedGrade(true)
    }
    setComment(value);
  }
  const debounced = useDebouncedCallback(
    // function
    () => {
      // console.log('!!!!!!!!!!!!!! updated debounce comment')
      submitPerformanceToTop(false)
    },
    // delay in ms
    1000
  );

  useEffect(() => {
    debounced();
  }, [debounced, comment]);

  useEffect(() => {
    selectedGrade && submitPerformanceToTop(false)
  }, [selectedGrade]);

  // useEffect(() => {
  //   if (
  //     student.id &&
  //     props.detailSkill.id &&
  //     selectedGrade &&
  //     selectedGrade.length > 0 &&
  //     comment &&
  //     comment.length > 0
  //   ) {
  //     console.log("§§§§§§§§§§§§ student.id: ", student.id);
  //     console.log(" props.detailSkill.id: ", props.detailSkill.id);
  //     console.log("selectedGrade: ", selectedGrade);
  //     console.log("comment: ", comment);

  //     props.submitPerformanceToTop(
  //       student.id,
  //       props.detailSkill.id,
  //       selectedGrade,
  //       comment
  //     );
  //   }
  // }, [comment, selectedGrade, student.id, props]);

  useEffect(() => {
    setHasNextStudent(props.currentStudentIndex < props.studentsLength - 1);
    setHasPrevStudent(props.currentStudentIndex > 0);
  }, [props.studentsLength, props.currentStudentIndex]);

  useEffect(() => {
    setHasNextSkill(props.currentSkillIndex < props.skillSetsLength - 1);
    setHasPrevSkill(props.currentSkillIndex > 0);
  }, [props.skillSetsLength, props.currentSkillIndex]);

  useEffect(() => {
    setHasNextSubSkill(
      props.currentSubSkillIndex < props.subSkillSetsLength - 1
    );
    setHasPrevSubSkill(props.currentSubSkillIndex > 0);
  }, [props.subSkillSetsLength, props.currentSubSkillIndex]);


  const addComment = (addedComment) => {
    const newComment =
      comment === "" ? addedComment : comment + " " + addedComment;
    setComment(newComment + " ");
  };
  // console.log('student.rank?.foreground_color: ', student.rank?.foreground_color);
  const submitPerformanceToTop = (closeGradeDetail = true) => {

    props.submitPerformance(
      student.id,
      props.detailSkill.id,
      selectedGrade,
      comment,
      closeGradeDetail,
      initialStudentPerformance,
      isChangedGrade

    );
  };
  const [skillSetOptions, setSkillSetOptions] = useState([])
  const [subSkillSetsOptions, setSubskillSetOptions] = useState([])
  const [studentOptions, setStudentOptions] = useState([])




  useEffect(() => {
    if (props?.allSkillSets?.length) {
      let options = props?.allSkillSets?.map(each => ({ label: each?.name, value: each?.id }))
      setSkillSetOptions(options)
    }
  }, [props.allSkillSets])

  useEffect(() => {
    let subSkillsetOptions = props?.skillSet?.grandchildren?.map(each => ({ label: each.name, value: each.id }))
    setSubskillSetOptions(subSkillsetOptions)
  }, [props?.skillSet])

  useEffect(() => {
    let subSkillsetOptions = props?.students?.map(student => ({
      label: `${student.name} ${student.last_name} ${student.age > 0 ? `-${student.age}` : ''}`,
      value: student.id
    }))
    setStudentOptions(subSkillsetOptions)
  }, [props?.students])


  const [showSkillsetDrop, setShowSkillSetDrop] = useState(false)
  const openSkillsetDropCard = () => setShowSkillSetDrop(true)
  const closeSkillsetDropCard = () => setShowSkillSetDrop(false)
  const [showSubSkillsetDrop, setShowSubSkillSetDrop] = useState(false)
  const openSubSkillsetDropCard = () => setShowSubSkillSetDrop(true)
  const closeSubSkillsetDropCard = () => setShowSubSkillSetDrop(false)
  const [showStudentDrop, setShowStudentDrop] = useState(false)
  const openStudentDropCard = () => setShowStudentDrop(true)
  const closeStudentDropCard = () => setShowStudentDrop(false)

  return (
    <>
      {showMediaModal?.show && <GradeDetailMediaUploadModal
        closer={mediaModalCloser}
        opener={mediaModalOpen}
        mediaTypeHandler={mediaTypeHandler}
        show={showMediaModal?.show}
        type={showMediaModal?.type}
      />}


      {student && (
        <div
          className={("  fixed z-50 inset-0 overflow-y-auto  xl:mt-0 top-z-index ") + (showMediaModal?.show ? ' goback-z-index' : ' ')}
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
          key={props.student?.id + props.detailSkill?.id}
        >
          <div className="flex  items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
              onClick={props.closeDetails}
            ></div>

            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="rating-modal inline-block align-bottom bg-white dark:bg-gray-900 rounded-lg text-left overflow-hidden shadow-xl transform  sm:my-8 sm:align-middle sm:max-w-lg sm:w-full -mt-4 pt-8">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 dark:bg-gray-900">
                <div className="sm:items-start">
                  <div className="pl-2 flex flex-row justify-around    w-full bg-martialup-400 mb-1 py-1 	 items-center	rounded-lg border-b border-gray-200	 ">
                    <ButtonNavigationLeft
                      clickHandler={props.prevSkill}
                      isActive={hasPrevSkill}
                    />
                    <span className="text-center text-sm   mb-1 px-2 py-1 rounded-b w-full relative">
                      <span className="cursor-pointer" onClick={openSkillsetDropCard}>
                        {props.skillSet?.name}
                      </span>


                      <DropCard
                        show={showSkillsetDrop}
                        setShow={setShowSkillSetDrop}
                      >
                        <div id="dropdownSmall" className="z-10  bg-white divide-y divide-gray-100 rounded shadow w-50 dark:bg-gray-700 dark:divide-gray-600">
                          <ul className="py-1 text-sm text-gray-700   dark:text-gray-200" aria-labelledby="dropdownSmallButton">
                            {
                              skillSetOptions?.map(skillSet => {
                                if (skillSet?.value == props?.skillSet?.id) {
                                  return null
                                }
                                return (
                                  <li
                                    onClick={() => {
                                      props.selectSkillSetById(skillSet?.value)
                                      closeSkillsetDropCard()

                                    }}
                                    className="block cursor-pointer  px-4 py-2 border-b  hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                    {skillSet?.label}
                                  </li>
                                )
                              }

                              )
                            }

                          </ul>
                        </div>
                      </DropCard>


                    </span>
                    {/* <span title="Skill Sets" className="text-center text-sm   mb-1 px-2 py-1 rounded-b w-full">
                      <Select
                        options={skillSetOptions}
                        onChange={(option) => {
                          props.selectSkillSetById(option?.value)
                        }}
                        value={skillSetOptions?.filter(each => each.value == props.skillSet?.id)}
                      />
                    </span> */}
                    <ButtonNavigationRight
                      clickHandler={props.nextSkill}
                      isActive={hasNextSkill}
                    />
                  </div>
                  <div className="pl-2 py-2 flex mt-2 flex-row justify-around  w-full	 bg-martialup-900 items-center	rounded-lg border-b border-gray-200  ">
                    <ButtonNavigationLeft
                      clickHandler={props.prevSubSkill}
                      isDark={true}
                      isActive={hasPrevSubSkill}
                    />
                    <span className="text-center text-xs xs:text-base   w-full  text-gray-50 mb-1 px-2 py-1 rounded-b relative">
                      <span className="cursor-pointer" onClick={openSubSkillsetDropCard}>
                        {props.detailSkill?.name}
                      </span>
                      <DropCard
                        show={showSubSkillsetDrop}
                        setShow={setShowSubSkillSetDrop}
                      >
                        <div id="dropdownSmall" className="z-10  bg-white divide-y divide-gray-100 rounded shadow w-50 dark:bg-gray-700 dark:divide-gray-600">
                          <ul className="py-1 text-sm text-gray-700   dark:text-gray-200" aria-labelledby="dropdownSmallButton">
                            {
                              subSkillSetsOptions?.map(subSkillSet => {
                                if (subSkillSet?.value == props?.detailSkill?.id) {
                                  return null
                                }
                                return (
                                  <li
                                    onClick={() => {
                                      props.selectSubSkillSetById(subSkillSet?.value)
                                      closeSubSkillsetDropCard()

                                    }}
                                    className="block cursor-pointer  px-4 py-2 border-b  hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                    {subSkillSet?.label}
                                  </li>
                                )
                              }

                              )
                            }

                          </ul>
                        </div>
                      </DropCard>
                    </span>
                    {/* <span title="SubSkill Sets" className="text-center text-xs xs:text-base   w-full bg-martialup-900  mb-1 shadow-md px-2 py-1 rounded-b">
                      <Select
                        options={subSkillSetsOptions}
                        onChange={(option) => {
                          props.selectSubSkillSetById(option?.value)
                        }}
                        value={subSkillSetsOptions?.filter(each => each.value == props.detailSkill?.id)}
                      />
                    </span> */}
                    <ButtonNavigationRight
                      clickHandler={props.nextSubSkill}
                      isDark={true}
                      isActive={hasNextSubSkill}
                    />
                  </div>
                  <div className="flex items-center ">
                    <div className="flex items-center  justify-center dark:bg-gray-800 mt-2 bg-gray-50 w-full rounded-lg align-middle p-2 border-b-2 border-gray-200">
                      <ButtonNavigationLeft
                        clickHandler={props.prevStudent}
                        isActive={hasPrevStudent}
                      />

                      <div
                        className={`flex items-center  justify-center  w-full  align-middle ${window.innerWidth < 640 ? "" : "p-2"
                          } `}
                      >
                        <div className="mr-4 flex flex-col align-middle justify-center ">
                          {student.avatar ? (
                            <UserAvatar detail={true} url={student.avatar} />
                          ) : (
                            <Avatar
                              className={`${props.hasFinished ? "w-6" : props.detail ? "w-28" : "h-full w-32"
                                } rounded-full shadow-md`}
                              name={student.name + " " + student.lastName}
                              round
                              maxInitials={2}
                              size={
                                props.hasFinished
                                  ? "30"
                                  : window.innerWidth < 640
                                    ? "50"
                                    : "70"
                              }
                              textSizeRatio={props.hasFinished ? 1.9 : 1.7}
                            />
                          )}
                        </div>
                        <div className="mr-4 flex flex-col 	w-full ">




                          <span className="text-left xs:text-2xl dark:text-indigo-200 text-martialup-900 mb-1 relative">
                            <span onClick={openStudentDropCard} className="cursor-pointer" >
                              {student.name + " " + student.last_name}
                              {student.age > 0 && <span className="inline ml-2 text-gray-400">
                                {student.age}
                              </span>}
                            </span>


                            <DropCard
                              show={showStudentDrop}
                              setShow={setShowStudentDrop}
                            >
                              <div id="dropdownSmall" className="z-10  bg-white divide-y divide-gray-100 rounded shadow w-44 dark:bg-gray-700 dark:divide-gray-600">
                                <ul className="py-1 text-sm text-gray-700   dark:text-gray-200" aria-labelledby="dropdownSmallButton">
                                  {
                                    studentOptions?.map(std => {
                                      if (std?.value == student?.id) {
                                        return null
                                      }
                                      return (
                                        <li
                                          onClick={() => {
                                            props.selectStudentById(std?.value)
                                            closeStudentDropCard()

                                          }}
                                          className="block cursor-pointer  px-4 py-2 border-b  hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                          {std?.label}
                                        </li>
                                      )
                                    }

                                    )
                                  }

                                </ul>
                              </div>
                            </DropCard>

                          </span>
                          {/* <span title="Students List" className=" text-center text-sm   mb-1 px-2 py-1 rounded-b w-full">


                            <Select
                              options={studentOptions}
                              onChange={(option) => {
                                props.selectStudentById(option?.value)
                              }}
                              value={studentOptions?.filter(each => each.value == student?.id)}
                            />
                          </span> */}
                          <div className=" flex flex-row  ml-2  w-full">
                            <span
                              className={`text-left ${window.innerWidth < 640 ? "mr-1" : "mr-10"
                                } text-xs xs:text-base text-gray-500 mb-1  `}
                            >
                              {props.groupName.length > 12 ? props.groupName.substring(0, 12) + '..' : props.groupName}
                            </span>
                            <span
                              className="text-left  text-xs xs:text-base  rounded-lg  border-gray-50 px-2 font-extrabold  shadow-md"
                              style={{
                                backgroundColor: student.rank?.foreground_color === '#ffff00' ? 'gray' : student.rank?.background_color,
                                color: student.rank?.foreground_color,
                                height: 'fit-content'
                              }}
                            >
                              {student.rank?.name}
                            </span>
                          </div>
                        </div>
                      </div>
                      <ButtonNavigationRight
                        clickHandler={props.nextStudent}
                        isActive={hasNextStudent}
                      />
                    </div>
                  </div>

                  <div className="flex items-center mt-2 ">
                    <div className="flex items-center dark:bg-gray-800 bg-gray-50 w-full rounded-lg align-middle p-2 border-b-2 border-gray-200 dark:border-black">
                      <div className="mr-4 flex flex-row justify-center gap-4	 w-full		">
                        <Grade
                          grade="A"
                          detailView={true}
                          hasMessage={"none"}
                          selectedGrade={selectedGrade}
                          onClick={() => {
                            if (isTestFinalized) {
                              toast.warn('Grades are finalized you can not update.', toastStyle);
                            } else
                              changeGrade("A")
                          }}
                        />
                        <Grade
                          grade="B"
                          detailView={true}
                          hasMessage={"none"}
                          selectedGrade={selectedGrade}
                          onClick={() => {
                            if (isTestFinalized) {
                              toast.warn('Grades are finalized you can not update.', toastStyle);
                            } else
                              changeGrade("B")
                          }}
                        />
                        <Grade
                          grade="C"
                          detailView={true}
                          hasMessage={"none"}
                          selectedGrade={selectedGrade}
                          onClick={() => {
                            if (isTestFinalized) {
                              toast.warn('Grades are finalized you can not update.', toastStyle);
                            } else
                              changeGrade("C")
                          }}
                        />
                        <Grade
                          grade="D"
                          detailView={true}
                          hasMessage={"none"}
                          selectedGrade={selectedGrade}
                          onClick={() => {
                            if (isTestFinalized) {
                              toast.warn('Grades are finalized you can not update.', toastStyle);
                            } else
                              changeGrade("D")
                          }
                          }
                        />
                        <Grade
                          grade="E"
                          detailView={true}
                          hasMessage={"none"}
                          selectedGrade={selectedGrade}
                          onClick={() => {
                            if (isTestFinalized) {
                              toast.warn('Grades are finalized you can not update.', toastStyle);
                            } else
                              changeGrade("E")
                          }
                          }
                        />
                        <Grade
                          grade="N/G"
                          detailView={true}
                          hasMessage={"none"}
                          selectedGrade={selectedGrade}
                          onClick={() => {
                            if (isTestFinalized) {
                              toast.warn('Grades are finalized you can not update.', toastStyle);
                            } else
                              changeGrade("N/G")
                          }}
                        />
                      </div>
                    </div>
                  </div>


                  {isTestFinalized !== true ?
                    <div className="flex justify-between align-center">
                      <div className="tabs flex mt-3">
                        <p className={reviewType == 'praise' ? " tab border-right active-tab" : "tab border-right "} onClick={() => setReviewType('praise')}>Praise</p>
                        <p className={reviewType == 'corrections' ? "tab active-tab" : " tab "} onClick={() => setReviewType('corrections')} > Corrections</p>
                      </div>
                      <div className="media-buttons flex pr-5 gap-4 align-center">
                        <div className="audio-icon flex align-center" onClick={() => mediaModalOpen('audio')}>
                          <Audio />
                        </div>
                        <div className="video-icon flex align-center" onClick={() => mediaModalOpen('video')}>
                          < Video />
                        </div>
                        <div className="image-icon flex align-center" onClick={() => mediaModalOpen('camera')}>
                          < Camera />
                        </div>
                      </div>
                    </div>


                    : null}


                  {reviewType == 'praise' ? isTestFinalized !== true ? (<div className="flex  items-center mt-2 ">
                    <div className="flex items-center dark:bg-gray-800  bg-gray-50 w-full rounded-lg align-middle p-1 sm:p-2 border-b-2 border-gray-200">
                      <div className="flex flex-col w-full">
                        <div className="mr-4 flex flex-row justify-between	 w-full		">
                          <div className="mr-4 flex flex-row justify-between	 w-full		mb-3">
                            <span className="text-xs text-gray-500">
                              {/* Praise */}
                            </span>
                            <span
                              className=" text-2xl text-green-400 cursor-pointer select-none "
                              onClick={props.openPraiseDetails}
                            >
                              <HiPlusCircle />
                            </span>
                          </div>
                        </div>
                        <div className="mr-4 flex scrollbar-hide overflow-scroll h-300 -mt-2 ">
                          <div className="flex  flex-wrap max-h-12">
                            {props.praises?.map((praise) => {
                              return (
                                <Praise
                                  selected={true}
                                  text={praise.name}
                                  onClick={() => changeComment(praise.name)}
                                  key={praise.name}
                                />
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>) : '' : null}
                  {reviewType == 'corrections' ? isTestFinalized !== true ? (<div className="flex items-center mt-2 ">
                    <div className="flex items-center bg-gray-50 w-full rounded-lg align-middle  p-1 sm:p-2 border-b-2 border-gray-200">
                      <div className="flex flex-col w-full">
                        <div className="mr-4 flex flex-row justify-between	 w-full		">
                          <div className="mr-4 flex flex-row justify-between	 w-full		mb-3">
                            <span className="text-xs text-gray-500">
                              {/* Corrections */}
                            </span>
                            <span
                              className=" text-2xl text-red-400 cursor-pointer "
                              onClick={props.openCorrectDetails}
                            >
                              <HiPlusCircle />
                            </span>
                          </div>
                        </div>
                        <div className="mr-4 flex overflow-scroll h-300  hide-scroll-bar -mt-2 md:mt-0">
                          <div className="flex flex-wrap max-h-16">
                            {props.corrects?.map((correct) => {
                              return (
                                <Correct
                                  selected={true}
                                  text={correct.name}
                                  key={correct.name}
                                  onClick={() => changeComment(correct.name)}
                                // onChange={(e) => debounced(e.target.value)}
                                />
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>) : '' : null}
                  <div className="flex items-center mt-2 ">
                    <div className="flex  flex-col dark:bg-gray-700 bg-gray-50 w-full rounded-lg align-middle p-2 border-b-2 border-gray-200">
                      <div className="">
                        <textarea
                          rows="2"
                          name="message"
                          id="message"
                          placeholder="Your Comment"
                          className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 dark:border-gray-700 rounded-md focus:outline-none focus:ring dark:focus:ring-gray-700 focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500  dark:focus:border-gray-500"
                          required
                          value={comment}
                          // onChange={(e) => setComment(e.target.value)}
                          onChange={(e) => setTextAreaComment(e.target.value)}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="px-4 mb-2 sm:px-6 sm:flex sm:flex-row-reverse">
                {/* <button
                  onClick={submitPerformanceToTop}
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Submit
                </button> */}
                <button
                  onClick={props.closeDetails}
                  type="button"
                  className=" close-modal-btn"
                >
                  <img src={closeSvg} className="h-6 opacity-50 " alt="close-icon" />
                  {/* <img src="https://img.icons8.com/material-rounded/24/000000/delete-sign.png" alt="close" />       */}
                </button>

              </div>
            </div>
          </div>
        </div>
      )
      }
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default GradeDetail;
