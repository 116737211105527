// import RadioButton from "./RadioButton";
import React, { useState } from "react";

import { HiPlusCircle } from "react-icons/hi";
// import Correct from "./Correct";
import Correct from "./Correct";
import { insertQuickResponse } from "../../services/api";
import { setQuickResponse } from "../../actions/login";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

let toastStyle = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};
function CorrectDetail(props) {
  const [newCorrect, setNewCorrect] = useState("");
  const [corrects, setCorrects] = useState(props.corrects);
  const storeCorrectHandler = (e) => {

    if (newCorrect.length > 1) {
      insertQuickResponse(newCorrect, newCorrect, 0, 1).then((response) => {
        console.log("response.data: ", response.success.data);
        if (response.success.data) {
          setCorrects([...corrects, response.success.data]);
          setNewCorrect('');
          props.setQuickResponse(response.success.data)
          toast.success('Correction Added Successfully.', toastStyle);
        }
      }).catch((err) => {
        toast.error('Unable to store Correction.', toastStyle);
        console.log("response.err: ", err);
      });

    } else {
      toast.warn('Minimum character for Correction is two.', toastStyle);
    }
  }
  return (
    <div
      className="fixed z-50 inset-0 overflow-y-auto"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        ></div>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:items-start">
              <div className="flex items-center mt-2 ">
                <div className="flex  flex-col  bg-gray-50 w-full rounded-lg align-middle p-2 border-b-2 border-gray-200">
                  <h3
                    className=" leading-6  text-gray-700 mb-2 font-light"
                    id="modal-title"
                  >
                    New Correct
                  </h3>
                  <div className="flex flex-row justify-between ">
                    <input
                      name="message"
                      id="message"
                      placeholder="Good Job!"
                      value={newCorrect}
                      onChange={(e) => {
                        setNewCorrect(e.target.value)
                      }}
                      className="w-10/12 px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500"
                      required
                    />
                    <span className=" text-5xl text-red-400">
                      <HiPlusCircle
                        onClick={storeCorrectHandler}
                      />
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex items-center mt-2 ">
                <div className="flex items-center bg-gray-50 w-full rounded-lg align-middle p-2 border-b-2 border-gray-200">
                  <div className="flex flex-col w-full">
                    <div className="mr-4 flex flex-row justify-between	 w-full		">
                      <div className="mr-4 flex flex-row justify-between	 w-full		mb-3">
                        <span className="text-xs text-gray-500">Corrects</span>
                      </div>
                    </div>
                    <div className="mr-4 flex overflow-x-scroll  hide-scroll-bar ">
                      <div className="flex flex-wrap max-h-48">
                        {corrects?.map((correct) => {
                          return (
                            <Correct
                              selected={false}
                              text={correct.name}
                              /* onClick={() => changeComment(praise.name)}*/
                              key={correct.name}
                            />
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              onClick={props.closeCorrectDetails}
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Done
            </button>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default connect(null, {
  setQuickResponse,
  // getPages,
})(CorrectDetail);;

