import React from 'react'
import Modal from '../Shared/Modal/Modal'
import ModalBody from '../Shared/Modal/ModalBody'
import ModalFooter from '../Shared/Modal/ModalFooter'
import ModalHeader from '../Shared/Modal/ModalHeader'
import ProgressBar from '../Shared/ProgressBar/ProgressBar'
import { ScoreIdtoGrade, SkillIdToPercentageChangehandler } from './ReUseFunctions'
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import UserAvatar from './Test/UserAvatar'
import Avatar from 'react-avatar'
import { useState } from 'react'
import Spinner from '../Helpers/Spinner'
import Grade from './Grade'
import pdfIcon from '../../assets/img/svg/pdfWhite.svg'
import ButtonNavigationLeft from './ButtonNavigationLeft'
import ButtonNavigationRight from './ButtonNavigationRight'
import { useSelector } from 'react-redux'

const StudentFullInfo = (
  {
    show,
    setShow,
    data,
    structuredData,
    setCurrentSkillInfo,
    AllSkillsInfo,
    openDetails,
    hasFinished,
    groupName,
    detail = '',
    setIsSingleInfo = () => { },
    isSingleInfo = false,
    nextStudentHandler,
    prevStudentHandler,
    buttonStatus
  }
) => {

    const closer = () => {
        setShow(false)
      setCurrentSkillInfo(AllSkillsInfo)
      if (isSingleInfo) {
        setIsSingleInfo(false)
      }
    }
    const [loading,setLoading] = useState(false)

  const {finalize_grade} = useSelector(state => state.test.test||{});
  
  const downloadPdfDocument = () => {
    [].forEach.call(document.querySelectorAll('.hide-Element'), function (el) {
      el.style.visibility = 'hidden';
    });
    [].forEach.call(document.querySelectorAll('.pdf-neg-mt'), function (el) {
      el.style.marginTop = '7px';
    });
    [].forEach.call(document.querySelectorAll('.pdf-neg-mt-10'), function (el) {
      el.style.marginTop = '-10px';
    });
    [].forEach.call(document.querySelectorAll('.pdf-pb-2'), function (el) {
      el.style.paddingBottom = '10px';
    });
    [].forEach.call(document.querySelectorAll('.pdf-pt-1'), function (el) {
      el.style.paddingTop = '5px';
    });
  

       // const input = document.getElementById("pdf-generate");
        // html2canvas(input)
        //     .then((canvas) => {
        //         const imgData = canvas.toDataURL('image/jpeg', 1)
        //         var imgWidth = 210; 
        //         var pageHeight = 295;  
        //         var imgHeight = canvas.height * imgWidth / canvas.width;
        //         var heightLeft = imgHeight;
        //         var doc = new jsPDF('p', 'mm');
        //         var position = 10; // give some top padding to first page
                
        //         doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);

        //         heightLeft -= pageHeight;
        //         while (heightLeft >= 0) {
        //           position += heightLeft - imgHeight; // top padding for other pages
        //           doc.addPage();
        //           doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        //           heightLeft -= pageHeight;
        //         }
        //         doc.save( 'file.pdf');
        //     })
         setLoading(true)
         let doc = new jsPDF('p', 'pt', 'a3');

        doc.html(document.getElementById('pdf-generate'), {
        // Adjust your margins here (left, top, right ,bottom)
            margin: [20, 20, 0, 70],
        callback: function (pdf) {
          pdf.output('dataurlnewwindow');
          [].forEach.call(document.querySelectorAll('.hide-Element'), function (el) {
            el.style.visibility = 'visible';
          });
          [].forEach.call(document.querySelectorAll('.pdf-neg-mt'), function (el) {
            el.style.marginTop = '15px';
          });
          [].forEach.call(document.querySelectorAll('.pdf-neg-mt-10'), function (el) {
            el.style.marginTop = '0px';
          });
          [].forEach.call(document.querySelectorAll('.pdf-pb-2'), function (el) {
            el.style.paddingBottom = '5px';
          });
          [].forEach.call(document.querySelectorAll('.pdf-pt-1'), function (el) {
            el.style.paddingTop = '5px';
          });
            setLoading(false)
            },
        });
       
    
  }
  
  

    const averageGradeHandler = (data) => {
        let sum = 0
        let count =0
        data?.map(each => {
            if (each && each?.score_id != 7) {
                sum = sum + each?.score_id
                count++;
              }
        })
        if (sum != 0) {
          let average = Math.round(sum / count) 
           let grade = ScoreIdtoGrade(average)
            return grade
        }
        else {
            return 'N/G'
        }
  }
  

  const gradeNowHandler = (clickSkillInfo) => {
    // openDetails()
     // console.log(data,'student data')
     // console.log(clickSkillInfo, 'skil data')
     openDetails(data?.id,clickSkillInfo)
}

    return (
        <div className='full-info-modal'>
             <Modal show={show} closeModal={closer} >
                    <ModalHeader title={`${data?.name} ${isSingleInfo ?structuredData[0]?.skillSet?.name:" Full "}   report`} closeModal={closer}  className="some class names for custom styles"/>
                <ModalBody className=" overflow-y-auto  fullinfo-body ">
                    <div id='pdf-generate'>

                    {
                   <>
                <div className="flex items-center w-full  dark:bg-gray-800 justify-between bg-gray-50 w-full rounded-lg align-middle p-2 border-b-2 border-gray-700">
                    
                    {!isSingleInfo&& <div className="hide-Element">
                    <ButtonNavigationLeft
                        clickHandler={prevStudentHandler}
                        isActive={buttonStatus?.prev}
                      />
                    </div>}
                  

                      <div
                        className={`flex items-center  justify-center    align-middle ${window.innerWidth < 640 ? "" : "p-2"
                          } `}
                      >
                        <div className="mr-4 flex flex-col align-middle justify-center ">
                          {data.avatar ? (
                            <UserAvatar detail={true} url={data.avatar} />
                          ) : (
                            <Avatar
                              className={`${hasFinished ? "w-6" : detail ? "w-28" : "h-full w-32"
                                } rounded-full shadow-md`}
                              name={data.name + " " + data.lastName}
                              round
                              maxInitials={2}
                              size={
                            hasFinished
                                  ? "30"
                                  : window.innerWidth < 640
                                    ? "50"
                                    : "70"
                              }
                              textSizeRatio={hasFinished ? 1.9 : 1.7}
                            />
                          )}
                        </div>
                        <div className="mr-4 flex flex-col 	w-full ">
                          <span className="text-left xs:text-2xl text-martialup-900  dark:text-indigo-300">
                            {data.name + " " + data.last_name}
                            {data.age > 0 && <span className="inline ml-2 text-gray-400">
                              {data.age}
                            </span>}
                          </span>
                          <div className=" flex flex-row   w-full mt-2">
                            <span
                              className={`text-left ${window.innerWidth < 640 ? "mr-1" : "mr-10"
                                } text-xs xs:text-base text-gray-500 mb-1   `}
                            >
                              {groupName.length > 12 ? groupName.substring(0, 12) + '..' : groupName}
                          </span>
                          <div
                            style={{
                              backgroundColor: data.rank?.foreground_color === '#ffff00' ? 'gray' : data.rank?.background_color,
                             }}
                            className="text-left  text-xs xs:text-base  rounded-lg  border-gray-50 px-2 pdf-pb-2 pdf-pt-1 font-extrabold  shadow-md">
                              <p
                                 className='pdf-neg-mt-10'
                                style={{
                                  
                                  color: data.rank?.foreground_color,
                                  height: 'fit-content'
                                }}
                              >
                                {data.rank?.name}
                              </p>
                           </div>
                         
                          </div>
                        </div>
                                </div>
                                

                                <div>
                    </div>
                  { !isSingleInfo&& <div className="hide-Element">
                    <ButtonNavigationRight
                        clickHandler={nextStudentHandler}
                        isActive={buttonStatus?.next}
                      />
                    </div>}
                   
                    </div>
                    <div className="mt-3 mb-4 dark:text-indigo-300">
                        { data?.preferred_name&&<div className="student-info-cont">
                            <div className="flex mb-1">
                                <p className='font-bold custom-label'>Preffered Name </p>
                                <p>{data?.preferred_name}</p>
                            </div>
                        </div>}
                        <div className="student-info-cont">
                            <div className="flex mb-1">
                                <p className='font-bold custom-label'>Address </p>
                                <p>{data?.address||'' +' '+data?.city||""}</p>
                            </div>
                        </div>
                        <div className="student-info-cont">
                            <div className="flex mb-1">
                                <p className='font-bold custom-label'>Phone </p>
                                <p>{data?.phone}</p>
                            </div>
                        </div>
                  {data?.special_needs&&<div className="student-info-cont">
                            <div className="flex mb-1">
                                <p className='font-bold custom-label'>Special Needs </p>
                                <p>{data?.special_needs}</p>
                            </div>
                        </div>}
  
                   </div> </>
                   }
                    <div className=" full-report-table-cont dark:text-indigo-300" >
                        <div className=" table-flex ">
                            <p className='head bg-primary text-white pl-3 py-2 ' >Skill Name</p>
                            <p className='head bg-primary text-white pl-3 py-2 ' >Grade</p>
                            <p className=' head bg-primary text-white pl-3 py-2' >Comment</p>
                        </div>
                        {structuredData?.map(each => (
                            <>
                            <p className='pl-3 py-2 bg-gray-300 dark:bg-gray-900 '>{each?.skillSet?.name}</p>
                                <div className=" ">
                                    {
                                        each?.skillSet?.grandchildren?.map((subSkill,i) => 
                                            <div className='table-flex border-bottom-grey bg-gray-50 dark:bg-gray-800 py-2'>
                                                <p className=' pl-3 py-2 bg-grey' >{subSkill?.name}</p>
                                                {/* <div className=" flex align-center pl-5 "> */}
                                                {/* <div className="rotate-90"> */}
                                                {/* <ProgressBar val={SkillIdToPercentageChangehandler(each?.studentGrades[i]?.score_id)} /> */}
                                                <div className='-ml-6 resize-75'>
                                                <Grade
                                                      grade={ScoreIdtoGrade(each?.studentGrades[i]?.score_id)}
                                                      detailView={true}
                                                      hasMessage={"none"}
                                            
                                                      onClick={() => { }}
                                                  />
                                                    </div>
                                                    {/* </div> */}
                                                    {/* <p className='  grade-z-index mr-2 flex-center' >{ ScoreIdtoGrade(each?.studentGrades[i]?.score_id)  }</p> */}
                                                 
                                                {/* </div> */}
                                            {((each?.studentGrades[i]?.score_id) && (each?.studentGrades[i]?.score_id != 7)) ?
                                              <p className=' pl-3 py-2' >{each?.studentGrades[i]?.comment}</p> :
                                              <button id=''
                                                className="btn-primary btn h-12 mt-2 hide-Element"
                                                onClick={() => gradeNowHandler(subSkill)}
                                                disabled = {finalize_grade==1}
                                              >
                                                Grade Now
                                              </button>
                                              }
                                            </div>
                                            )
                                    }
                                </div>
                                <div className=" pl-3 table-flex  mb-3">
                                    <p className=' mt-4 font-bold '>{`Average Grade :   `}</p>
                                    <div className='-ml-9 resize-75'>
                                    <Grade
                                            grade={averageGradeHandler(each?.studentGrades)}
                                            detailView={true}
                                            hasMessage={"none"}
                                  
                                            onClick={() => { }}
                                         />
                                    </div>
                                    <div></div>
                                   
                                </div>
                
                           </>
                        ))}
                        </div>
                        
                    </div>
                    <Spinner spin={loading} size={60} />

                    </ModalBody>
                    <ModalFooter className="some class names for custom styles">
            {!isSingleInfo&& <button className="btn-primary btn flex justify-center flex-center" onClick={downloadPdfDocument}>
              <img src={pdfIcon} className='mr-2 h-4' alt="" />
              Download
            </button>}
                    </ModalFooter>
            </Modal>
        </div>
    )
}             

export default StudentFullInfo
