import React from 'react'
import { useSelector } from 'react-redux'
import './Modal.css'

const Modal = (props) => {
    const theme = useSelector(state => state.theme)
    return (
        <>
        {props.show&&<div className={'modal-root-cont '} data-theme={theme}><div className='root-modal-overlay' onClick={props.closeModal}> </div >
            <div className={"root-modal-container dark:bg-gray-900 bg-white "+props.className}>
            {props.children}
            </div>
           </div>}
            </>
    )
}

export default Modal
