import React from 'react'
import Grade from '../../SubComponents/Grade'
import './RoundedProgressBar.css'


const RoundedProgressBar = ({ percentage, className,each }) => {
  
    const colorGeneratehandler = () => {
        if (percentage < 1) {
            return 'pink'
        }
        if (percentage < 30) {
            return 'red'
        }
        else if (percentage < 60) {
            return 'blue'
        }
         else if (percentage <= 100) {
            return 'green'
        }
    }

    const averageGradeHandler = (data) => {
        let sum = 0
        let count =0
        data?.map(each => {
            if (each && each?.score_id != 7) {
                sum = sum + each?.score_id
                count++;
              }
        })
        if (sum != 0) {
            let average = Math.round(sum / count) 
            if (average == 2) {
                 return 'A'
             }
            else if (average == 3) {
                 return 'B'
             }
            else if (average == 4) {
                 return 'C'
             }
             else if (average == 5) {
                 return 'D'
             }
            else if (average == 6) {
                 return 'E'
             }
        }
        else {
            return 'N/G'
        }
    }
    
    return (
        <div className={"progress-round-cont " + className}>
            <div class={`c100 p${percentage} ${colorGeneratehandler()}`}>
                <div className="grade-info-inside">
                <Grade
                                            grade={averageGradeHandler(each?.studentGrades)}
                                            detailView={true}
                                            hasMessage={"none"}
                                            onClick={() => { }}
                                         />
                             </div>
                                 
                                  
            <div class="slice">
                <div class="bar"></div>
                <div class="fill"></div>
            </div>
            </div>
        </div>

    )
}

export default RoundedProgressBar
