import React, { useEffect, useRef } from 'react'
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import VideoRecorder from 'react-video-recorder'

const VideoRecorderComponent = () => {
  const [videoBlob,setVideoBlob] = useState()
  return (
    <>
        <VideoRecorder
      
      onRecordingComplete={videoBlob => {
        // Do something with the video...
          console.log('videoBlob', videoBlob)
          setVideoBlob(videoBlob)
          let some = document.getElementsByClassName('dooBMd')[0];
          let url = URL.createObjectURL(videoBlob)
          if (some) {
            some.src = url;
            some.play();
          }
   
          URL.revokeObjectURL(url);
        }}
        onStartRecording={() => {
          console.log('started')
          setVideoBlob(null)
        }}
      
      />
      <button className="btn btn-primary mt-3"  disabled={!videoBlob}>Upload Review</button>
    </>

      
    //   <div className="media-buttons flex pr-5 gap-4 align-center">
    //   <div className="audio-icon flex align-center">
    //     <Audio />
    //   </div>
    //   <div className="video-icon flex align-center">
    //     < Video />
    //   </div>
    //   <div className="image-icon flex align-center">
    //     < Camera />
    //   </div>
    // </div>
  );
};

export default VideoRecorderComponent

