import { useEffect, useState } from "react";


const useDarkMode = () => {
    const [theme, setTheme] = useState(localStorage?.MartialTheme)
    const colorTheme = theme ==='light'?'dark':'light'
    
    useEffect(() => {
        const root = window.document.documentElement;
        root.classList.add(theme)
        root.classList.remove(colorTheme)
        localStorage.setItem('MartialTheme',theme)
    },[theme,colorTheme])
    return ([colorTheme,setTheme])
}

export default useDarkMode
