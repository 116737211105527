import Grade from "./Grade";
import Avatar from "react-avatar";
import UserAvatar from "./Test/UserAvatar";
import { finalGrade, verifyFinalGrade } from "../../services/api";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import ProgressBar from "../Shared/ProgressBar/ProgressBar";
import { useEffect, useState } from "react";
import RoundedProgressBar from "../Shared/RoundedProgressBar/RoundedProgressBar";
import { progressCirclePercentageHandler } from "./ReUseFunctions";
// import { guidGenerator } from "../../services/util";

function Row(props) {
  const { student, setStudentInfo, skillSets } = props;
  let { page } = useParams();
  const [structuredData, setStructuredData] = useState()
  // console.log(
  //   "props.selectedSkillSet?.grandchildren: ",
  //   props.selectedSkillSet
  // );
  // console.log("props.keyRow: ", props.keyRow);
  // console.log("window.innerWidth : ", window.innerWidth);
  const { test } = useSelector(store => store.test || {})
  let isTestFinalized = test?.finalize_grade > 0

  let { performances } = useSelector(store => store.test)
  useEffect(() => {
    let structuredData = []
    skillSets?.map(eachSkillset => {
      let SingleData = { skillSet: eachSkillset, studentGrades: [] }
      eachSkillset?.grandchildren?.map((subSkillSet) => {
        const studentGrade = performances?.filter(
          (perf) => perf.user_id === student.id && perf.skill_id === subSkillSet.id
        )
        if (studentGrade?.length) {
          SingleData.studentGrades.push(studentGrade[0])
        }
        else {
          SingleData.studentGrades.push(null)
        }

      })
      structuredData.push(SingleData)
    })
    setStructuredData(structuredData)
  }, [skillSets, student, performances])
  return (
    <tr
      // key={props.keyRow }
      className={`border-b dark:bg-gray-700 dark:border-black  ${props.hasFinished
        ? "border-gray-300 bg-gray-200 hover:bg-gray-300"
        : "border-gray-200 bg-gray-50 hover:bg-gray-100"
        } `}
    >
      <td
        className={`py-3 px-6 text-left sticky dark:border-black  dark:bg-gray-700  left-0 z-10 ${props.hasFinished
          ? "bg-gray-200 hover:bg-gray-300"
          : "bg-gray-100 hover:bg-gray-200"
          } `}

        onClick={() => { setStudentInfo({ show: true, info: student }) }}
      >
        <div
          className={`flex   items-center ${props.hasFinished ? "space-between" : "justify-center"
            } `}
        >
          <div
            className={`-center items-center align-center flex ${props.hasFinished ? "flex-row" : "mr-2 flex-col revert-flex-col "
              } `}
          >
            {student.avatar ? (
              <UserAvatar
                hasFinished={props.hasFinished}
                url={student.avatar}
              />
            ) : (
              <Avatar
                name={student.name + " " + student.lastName}
                round
                maxInitials={2}
                size={
                  props.hasFinished
                    ? "30"
                    : window.innerWidth < 600
                      ? "60"
                      : "4rem"
                }
              // textSizeRatio={props.hasFinished ? 1.9 : 1.7}
              // textMarginRatio={0.2}
              />
            )}
            <>

              {/* <span
                className={`self-center text-xs  ${props.hasFinished ? "" : ""
                  } font-bold text-green-600  `}
              >
                4/5
              </span> */}
            </>
            {/* )} */}
            {/* need to work here */}
            <div className="flex flex-col revert-ml-2 ">
              <div className="flex justify-center mt-1">
                {
                  structuredData?.map(each => (
                    <ProgressBar
                      defaultColor
                      val={progressCirclePercentageHandler(each)} />
                  ))
                }
              </div>
              <span
                className={`self-center text-xs mt-1 dark:text-gray-100   ${props.hasFinished ? "ml-1 " : ""
                  }   `}
              >
                {props.hasFinished
                  ? student.name.substring(0, 3)
                  : student.name}
              </span>
            </div>

          </div>
        </div>
      </td>
      {props.selectedSkillSet?.grandchildren?.map((skillSet) => {
        const studentGrade = props?.performances?.filter(
          (perf) => perf.user_id === student.id && perf.skill_id === skillSet.id
        );

        const scoreName = studentGrade && studentGrade[0]
          ? props?.scores?.find((score) => score.id == studentGrade[0]?.score_id)
            ?.name
          : props?.scores[props.scores.length - 1]?.name;


        return (
          <td
            className="py-3 px-6 text-left dark:text-gray-100 "
            key={props.keyRow + skillSet.name}
          >
            <Grade
              hasFinished={props.hasFinished}
              grade={scoreName} //check if the skill evaluated
              id={null}
              hasMessage={studentGrade && studentGrade[0]?.comment}
              onClick={() => {
                if (localStorage.getItem('finalizedTests') !== null) {
                  let finalizedTests = JSON.parse(localStorage.getItem('finalizedTests'));
                  var item = finalizedTests.find(x => x.id == page);
                  if (isTestFinalized) {
                    // alert('This test grades are already finalized')
                    return props.openDetails(student.id, skillSet)
                  }
                  else {
                    return props.openDetails(student.id, skillSet)
                  }
                }
                return props.openDetails(student.id, skillSet)
              }
              }
              isOnTestView={true}
            // skillSet={skillSet}
            // student={student}
            />
          </td>
        );
      })}

      {/* <td className="py-3 px-6 text-center">
        <div className="flex items-center justify-center ">
          <Grade grade="N/A" hasMessage={false} />
        </div>
      </td>
      <td className="py-3 px-6 text-center">
        <div className="flex items-center justify-center ">
          <Grade grade="A" hasMessage={false} />
        </div>
      </td>
      <td className="py-3 px-6 text-center">
        <div className="flex items-center justify-center ">
          <Grade grade="A" hasMessage={false} />
        </div>
      </td> */}
    </tr>
  );
}
export default Row;
