/* eslint-disable import/no-anonymous-default-export */
import {
  SET_TEST_DATA,
  SET_TEST_SELECTED_GROUP,
  INSERT_PERFORMANCE_DATA,
  UPDATE_PERFORMANCE_DATA,
  DELETE_PERFORMANCE_DATA,
  GET_PERFORMANCE_DATA,
  INSERT_PERFORMANCE_DATA_DIRECTLY,
  UPDATE_PERFORMANCE_DATA_BY_SKILL_USER_TEST,
  SET_STRUCTURED_DATA,
} from "../actions/actionTypes";

export default (
  state = {
    test: null,
    groups: [],
    selectedGroup: null,
    performances: [],
    performancesLoaded: false,
    structuredData: null,
  },
  action
) => {
  switch (action.type) {
    case SET_TEST_DATA:
      // console.log("action.type", action.type); //TODO remove
      console.log("action.payload", action.payload); //TODO remove
      const groups = action.payload.success.groups;
      return {
        ...state,
        test: action.payload.success.test,
        groups,
        selectedGroup: groups[0] ? groups[0] : null,
      };
    case SET_TEST_SELECTED_GROUP:
      return {
        ...state,
        selectedGroup: action.payload,
      };
    case GET_PERFORMANCE_DATA:
      return {
        ...state,
        performances: action.payload,
        performancesLoading: false
      };
    // return {
    //   ...state,
    //   performances: [...state.performances, action.payload],
    // };
    case INSERT_PERFORMANCE_DATA_DIRECTLY: {
      const updatedPerformance = action.payload;
      const performanceToUpdate = state.performances.find(
        (perf) =>
          perf.evaluator_id === updatedPerformance.evaluator_id &&
          perf.skill_id === updatedPerformance.skill_id &&
          perf.test_id === updatedPerformance.test_id &&
          perf.user_id === updatedPerformance.user_id
      );
      if (performanceToUpdate) {
        updatedPerformance.id = performanceToUpdate.id;
      }

      const oldPerformances = state.performances.filter(
        (perf) => perf.id !== updatedPerformance.id
      );

      console.log(`updatedPerformance`, updatedPerformance);
      console.log(`performanceToUpdate`, performanceToUpdate);
      console.log(`oldPerformances`, oldPerformances);
      return {
        ...state,
        performances: [...oldPerformances, updatedPerformance],
      };
    }
    case UPDATE_PERFORMANCE_DATA_BY_SKILL_USER_TEST: {
      const updatedPerformance = action.payload;
      console.log('updatedPerformance: ', updatedPerformance);
      const performanceToUpdate = state.performances.find(
        (perf) =>
          perf.evaluator_id === updatedPerformance.evaluator_id &&
          perf.skill_id === updatedPerformance.skill_id &&
          perf.test_id === updatedPerformance.test_id &&
          perf.user_id === updatedPerformance.user_id
      );
      // if (performanceToUpdate) {
      //   performanceToUpdate.id = updatedPerformance.id;
      // }

      console.log('state.performances: ', state.performances);
      const oldPerformances = performanceToUpdate ? state.performances.filter(
        (perf) => perf.id !== performanceToUpdate.id
      ) : state.performances;

      // console.log(`updatedPerformance`, updatedPerformance);
      // console.log(`performanceToUpdate`, performanceToUpdate);
      // console.log(`oldPerformances`, oldPerformances);
      return {
        ...state,
        performances: [...oldPerformances, updatedPerformance],
      };
    }

    case INSERT_PERFORMANCE_DATA:
    case UPDATE_PERFORMANCE_DATA:
      const updatedPerformance = action.payload;
      const oldPerformances = state.performances.filter(
        (perf) => perf.id !== updatedPerformance.id
      );
      console.log(`updatedPerformance`, updatedPerformance);
      console.log(`oldPerformances`, oldPerformances);
      return {
        ...state,
        performances: [...oldPerformances, updatedPerformance],
      };
    case DELETE_PERFORMANCE_DATA: {
      const performanceToBeDeleted = action.payload;
      const performances = state.performances.filter(
        (perf) => perf.id !== performanceToBeDeleted.id
      );
      console.log(`performanceToBeDeleted`, performanceToBeDeleted);
      console.log(`performances`, performances);
      return {
        ...state,
        performances,
      };
    }
    case SET_STRUCTURED_DATA: {

      return {
        ...state,
        structuredData: action.payload,
      };
    }

    default:
      return state;
  }
};
