
export const THEME_CHANGE = "THEME_CHANGE";
export const PAGE_NO_CHANGE = "PAGE_NO_CHANGE";
export const GET_PAGES = "GET_PAGES";
export const RESET_PAGES = "RESET_PAGES";
export const PAGE_LOADED = "PAGE_LOADED";
export const SET_LOGIN_PENDING = "SET_LOGIN_PENDING";
export const SET_LOGIN_SUCCESS = "SET_LOGIN_SUCCESS";
export const SET_BUTTON_SCALE_FACTOR = "SET_BUTTON_SCALE_FACTOR";
export const SET_GRADINGS = "SET_GRADINGS";
export const SET_GRADINGS_FROM_LOCAL = "SET_GRADINGS_FROM_LOCAL";
export const SET_TEST_DATA = "SET_TEST_DATA";
export const SET_TEST_SELECTED_GROUP = "SET_TEST_SELECTED_GROUP";
export const SET_SELECTED_SKILL_SET = "SET_SELECTED_SKILL_SET";
export const SET_SELECTED_SUB_SKILL_SET = "SET_SELECTED_SUB_SKILL_SET";
export const INSERT_PERFORMANCE_DATA = "INSERT_PERFORMANCE_DATA";
export const INSERT_PERFORMANCE_DATA_DIRECTLY = "INSERT_PERFORMANCE_DATA_DIRECTLY";
export const UPDATE_PERFORMANCE_DATA = "UPDATE_PERFORMANCE_DATA";
export const DELETE_PERFORMANCE_DATA = "DELETE_PERFORMANCE_DATA";
export const GET_PERFORMANCE_DATA = "GET_PERFORMANCE_DATA";
export const UPDATE_PERFORMANCE_DATA_BY_SKILL_USER_TEST = "UPDATE_PERFORMANCE_DATA_BY_SKILL_USER_TEST";
export const SET_PRAISES = "SET_PRAISES";
export const SET_STRUCTURED_DATA = "SET_STRUCTURED_DATA";



